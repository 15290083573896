<template>
  <aside class="lego-menu menu px-4" :class="{ 'is-active': mobileMenuActive }">
    <LegoHeader class="py-6 is-menu">{{ appTitle }}</LegoHeader>
    <div class="navbar-brand">
      <a
        :class="{ 'is-active': mobileMenuActive }"
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        :aria-expanded="mobileMenuActive"
        @click="mobileMenuActive = !mobileMenuActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
      <h3 class="title is-size-4 navbar-item">
        {{ appTitle }}
      </h3>
    </div>

    <div class="main-menu pb-5" :class="{ 'is-active': mobileMenuActive }">
      <ul class="menu-list mb-5">
        <li v-for="({ url, label, icon }, index) in menu" :key="index">
          <router-link
            :class="{ 'is-active': route.path === url }"
            class="is-flex is-align-items-center"
            :to="url"
            @click="onMenuItemClick"
          >
            <LegoIcon :icon="icon" />
            <span class="pl-3">{{ label }}</span>
          </router-link>
        </li>
      </ul>
      <a
        class="button is-light is-rounded mb-5 is-fullwidth"
        href="#"
        @click.prevent="openSupportTicket"
        >Need help?</a
      >
      <router-link
        class="button is-light is-rounded mb-5 is-fullwidth"
        to="/order"
        @click="onMenuItemClick"
        >Order form</router-link
      >
      <ul class="menu-list mb-6">
        <li v-if="authUser">
          <div class="px-3">
            <p>You are logged in as:</p>
            <p
              :title="authUser.email"
              class="has-text-weight-bold mb-4 user-email"
            >
              {{ authUser.email }}
            </p>
          </div>
        </li>
        <li
          v-for="({ url, label, icon, action, classNames }, index) in subMenu"
          :key="index"
        >
          <router-link
            v-if="url"
            class="is-flex is-align-items-center"
            :to="url"
            :class="classNames"
            @click="onMenuItemClick"
          >
            <LegoIcon :icon="icon" />
            <span class="pl-3">{{ label }}</span>
          </router-link>
          <button
            :disabled="isLoading"
            v-else-if="action"
            class="button is-fullwidth"
            @click="action"
          >
            <LegoIcon :icon="icon" />
            <span class="pl-3">{{ label }}</span>
          </button>
        </li>
      </ul>

      <ul v-if="authUser && digiboxes.length" class="menu-list mb-5">
        <li
          v-for="({ label, statusCode }, index) in digiboxes"
          :key="index"
          class="mb-5 pl-3"
        >
          <LegoBoxStatus :label="label" :code="statusCode" />
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import LegoIcon from '@/components/base/LegoIcon';
import LegoHeader from '@/components/base/LegoHeader';
import LegoBoxStatus from '@/components/base/LegoBoxStatus';

export default {
  name: 'LegoMenu',
  components: { LegoBoxStatus, LegoHeader, LegoIcon },
  setup() {
    const route = useRoute();
    const store = useStore();

    const mobileMenuActive = ref(false);

    const authUser = computed(() => store.getters.authUser);
    const isLoading = computed(() => store.getters.isLoading);
    const digiboxes = computed(() => store.getters.digiboxes);
    const menu = computed(() => {
      let result = [
        {
          label: 'Home',
          url: '/',
          icon: 'fa-home',
        },
        {
          label: 'Updates',
          url: '/updates',
          icon: 'fa-exclamation-circle',
          isHidden: !authUser.value,
        },
        {
          label: 'Requirements',
          url: '/requirements',
          icon: 'fa-lightbulb',
        },
        {
          label: 'Terms of use',
          url: '/terms-of-use',
          icon: 'fa-book',
        },
        {
          label: 'Installation procedure',
          url: '/installation-procedure',
          icon: 'fa-tools',
        },
        {
          label: 'FAQ',
          url: '/faq',
          icon: 'fa-question-circle',
          isHidden: !authUser.value,
        },
        // @todo: waiting for Support (POST) API
        // {
        //   label: 'Support',
        //   url: '/support',
        //   icon: 'fa-comments',
        //   isHidden: !authUser.value,
        // },
      ];

      return result.filter((item) => !item.isHidden);
    });

    const subMenu = computed(() => {
      let result = [
        {
          label: 'Log in',
          url: '/login',
          icon: 'fa-lock',
          classNames: 'button is-primary is-rounded',
          isHidden: authUser.value,
        },
        {
          label: 'Log out',
          action: () => {
            onMenuItemClick();
            doLogout();
          },
          icon: 'fa-lock-open',
          classNames: '',
          isHidden: !authUser.value,
        },
      ];

      return result.filter((item) => !item.isHidden);
    });

    const doLogout = async () => {
      await store.dispatch('doLogout');
    };

    const onMenuItemClick = () => (mobileMenuActive.value = false);

    return {
      route,
      menu,
      subMenu,
      authUser,
      digiboxes,
      mobileMenuActive,
      isLoading,
      doLogout,
      onMenuItemClick,
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
  methods: {
    openSupportTicket() {
      window.FreshworksWidget('open');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/helpers';

.lego-menu {
  font-size: 1.125rem;

  @media (max-width: $lego-breakpoints-tablet) {
    &.is-active {
      background: #fff;
    }
    background: $lego-body-bg-color;
  }

  .navbar-brand {
    display: flex;

    @media (min-width: $lego-breakpoints-tablet) {
      display: none;
    }
  }

  .navbar-burger {
    margin-left: 0;

    &:hover {
      background: transparent;
    }
  }

  .main-menu {
    display: none;
    margin-top: 1rem;

    &.is-active {
      display: block;
    }

    @media (min-width: $lego-breakpoints-tablet) {
      margin-top: 0;
      display: block;
    }

    .user-email {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.lego-header {
  &.is-menu {
    display: none;

    @media (min-width: $lego-breakpoints-tablet) {
      display: block;
    }
  }
}
</style>
