<template>
  <header class="lego-collapsible-item">
    <slot name="sectionHeader" />
    <LegoInfoBox tabindex="0" @click="tryToExpand" @keypress="tryToExpand">
      <div
        class="lego-collapsible-item-header is-flex is-justify-content-space-between is-align-items-center"
      >
        <h3 class="subtitle is-size-3 mb-0 subtitle-mobile">
          <slot name="header"></slot>
        </h3>
        <LegoIcon
          v-if="collapsible"
          :icon="collapsibleIcon"
          class="lego-collapsible-icon"
        />
      </div>
      <div
        v-show="!isCollapsed"
        class="lego-collapsible-item-body mt-5"
        @click.stop
      >
        <slot name="content" />
      </div>
    </LegoInfoBox>
  </header>
</template>

<script>
import { ref, toRefs, computed, watch, onMounted } from 'vue';
import LegoInfoBox from '@/components/base/LegoInfoBox';
import LegoIcon from '@/components/base/LegoIcon';

export default {
  name: 'LegoCollapsibleItem',
  components: { LegoIcon, LegoInfoBox },
  props: {
    collapsible: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { collapsible, expanded } = toRefs(props);
    let isCollapsed = ref(true);

    const collapsibleIcon = computed(() => {
      return isCollapsed.value ? 'fa-chevron-down' : 'fa-chevron-up';
    });

    onMounted(() => {
      isCollapsed.value = initCollapsedState();
    });

    watch(expanded, () => {
      isCollapsed.value = initCollapsedState();
    });

    const initCollapsedState = () => {
      return !expanded.value && collapsible.value;
    };

    const tryToExpand = () => {
      if (collapsible.value) {
        isCollapsed.value = !isCollapsed.value;
      }
    };

    return {
      isCollapsed,
      collapsibleIcon,
      tryToExpand,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/helpers';

$lego-collapsible-icon-size: 1.2rem;

.lego-collapsible-icon {
  font-size: $lego-collapsible-icon-size;
  height: $lego-collapsible-icon-size;
  width: $lego-collapsible-icon-size;

  @media (min-width: $lego-breakpoints-tablet) {
    font-size: 2rem;
  }
}
</style>
