export const LoginFormFields = {
  login: {
    name: 'field--login-login',
    placeholder: 'Username',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
  password: {
    name: 'field--login-email',
    placeholder: 'Password',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
};
