<template>
  <div class="lego-step-02">
    <div class="order-form-fields mb-6">
      <h3 class="subtitle mb-3">Requested delivery date:</h3>
      <p class="is-small-text mb-2">Please expect two weeks for delivery</p>
      <LegoDatepickerInput
        v-model="state.requestedDeliveryDate"
        :disabled-date="disabledRequestedDeliveryDates"
        :required="formFields.requestedDeliveryDate.required"
        :name="formFields.requestedDeliveryDate.name"
        :maxlength="formFields.requestedDeliveryDate.maxlength"
        :placeholder="formFields.requestedDeliveryDate.placeholder"
        :validation="v$.requestedDeliveryDate"
        @input="v$.requestedDeliveryDate.$touch"
      />
    </div>

    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">
        Contact person (for customs purposes):
        <LegoIcon
          icon="fa-info-circle"
          namespace="fas"
          class="has-tooltip-arrow has-tooltip-right has-tooltip-multiline"
          data-tooltip="This person should be able to answer questions specifically regarding customs and import"
        />
      </h3>
      <LegoTextInput
        v-model.trim="state.contactPersonName"
        :required="formFields.contactPersonName.required"
        :name="formFields.contactPersonName.name"
        :maxlength="formFields.contactPersonName.maxlength"
        :placeholder="formFields.contactPersonName.placeholder"
        :validation="v$.contactPersonName"
        class="mb-5"
        @input="v$.contactPersonName.$touch"
      />

      <div class="columns is-vcentered is-variable is-1 is-mobile">
        <div class="column is-narrow">
          <LegoIcon icon="fa-plus" namespace="fas" class="light-icon" />
        </div>
        <div class="column is-4">
          <LegoTextInput
            v-model.trim="state.contactPersonPhoneCountryCode"
            :required="formFields.contactPersonPhoneCountryCode.required"
            :name="formFields.contactPersonPhoneCountryCode.name"
            :maxlength="formFields.contactPersonPhoneCountryCode.maxlength"
            :placeholder="formFields.contactPersonPhoneCountryCode.placeholder"
            :validation="v$.contactPersonPhoneCountryCode"
            @input="v$.contactPersonPhoneCountryCode.$touch"
          />
        </div>
        <div class="column">
          <LegoTextInput
            v-model.trim="state.contactPersonPhoneNumber"
            :required="formFields.contactPersonPhoneNumber.required"
            :name="formFields.contactPersonPhoneNumber.name"
            :maxlength="formFields.contactPersonPhoneNumber.maxlength"
            :placeholder="formFields.contactPersonPhoneNumber.placeholder"
            :validation="v$.contactPersonPhoneNumber"
            @input="v$.contactPersonPhoneNumber.$touch"
          />
        </div>
      </div>

      <LegoTextInput
        v-model.trim="state.contactPersonEmail"
        :required="formFields.contactPersonEmail.required"
        :name="formFields.contactPersonEmail.name"
        :maxlength="formFields.contactPersonEmail.maxlength"
        :placeholder="formFields.contactPersonEmail.placeholder"
        input-type="email"
        :validation="v$.contactPersonEmail"
        @input="v$.contactPersonEmail.$touch"
      />
    </div>

    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">Delivery address:</h3>
      <LegoRadioGroup
        v-model="state.choiceOfDelivery"
        :required="formFields.choiceOfDelivery.required"
        :name="formFields.choiceOfDelivery.name"
        :options="formFields.choiceOfDelivery.options"
      />

      <div v-if="showHiddenFields" class="order-form-fields-dynamic mb-6">
        <LegoTextInput
          v-model.trim="state.deliveryDifferentCompanyName"
          :required="formFields.deliveryDifferentCompanyName.required"
          :name="formFields.deliveryDifferentCompanyName.name"
          :maxlength="formFields.deliveryDifferentCompanyName.maxlength"
          :placeholder="formFields.deliveryDifferentCompanyName.placeholder"
          :validation="v$.deliveryDifferentCompanyName"
          @input="v$.deliveryDifferentCompanyName.$touch"
        />
        <LegoTextInput
          v-model.trim="state.deliveryDifferentAddress"
          :required="formFields.deliveryDifferentAddress.required"
          :name="formFields.deliveryDifferentAddress.name"
          :maxlength="formFields.deliveryDifferentAddress.maxlength"
          :placeholder="formFields.deliveryDifferentAddress.placeholder"
          :validation="v$.deliveryDifferentAddress"
          @input="v$.deliveryDifferentAddress.$touch"
        />
        <LegoTextInput
          v-model.trim="state.deliveryDifferentZipCodeCity"
          :required="formFields.deliveryDifferentZipCodeCity.required"
          :name="formFields.deliveryDifferentZipCodeCity.name"
          :maxlength="formFields.deliveryDifferentZipCodeCity.maxlength"
          :placeholder="formFields.deliveryDifferentZipCodeCity.placeholder"
          :validation="v$.deliveryDifferentZipCodeCity"
          @input="v$.deliveryDifferentZipCodeCity.$touch"
        />
        <LegoTextInput
          v-model.trim="state.deliveryDifferentCountry"
          :required="formFields.deliveryDifferentCountry.required"
          :name="formFields.deliveryDifferentCountry.name"
          :maxlength="formFields.deliveryDifferentCountry.maxlength"
          :placeholder="formFields.deliveryDifferentCountry.placeholder"
          :validation="v$.deliveryDifferentCountry"
          @input="v$.deliveryDifferentCountry.$touch"
        />
        <LegoTextInput
          v-model.trim="state.deliveryDifferentContactPerson"
          :required="formFields.deliveryDifferentContactPerson.required"
          :name="formFields.deliveryDifferentContactPerson.name"
          :maxlength="formFields.deliveryDifferentContactPerson.maxlength"
          :placeholder="formFields.deliveryDifferentContactPerson.placeholder"
          :validation="v$.deliveryDifferentContactPerson"
          class="mb-5"
          @input="v$.deliveryDifferentContactPerson.$touch"
        />

        <div class="columns is-vcentered is-variable is-1 is-mobile">
          <div class="column is-narrow">
            <LegoIcon icon="fa-plus" namespace="fas" class="light-icon" />
          </div>
          <div class="column is-4">
            <LegoTextInput
              v-model.trim="state.deliveryDifferentPhoneCountryCode"
              :required="formFields.deliveryDifferentPhoneCountryCode.required"
              :name="formFields.deliveryDifferentPhoneCountryCode.name"
              :maxlength="
                formFields.deliveryDifferentPhoneCountryCode.maxlength
              "
              :placeholder="
                formFields.deliveryDifferentPhoneCountryCode.placeholder
              "
              :validation="v$.deliveryDifferentPhoneCountryCode"
              @input="v$.deliveryDifferentPhoneCountryCode.$touch"
            />
          </div>
          <div class="column">
            <LegoTextInput
              v-model.trim="state.deliveryDifferentPhone"
              :required="formFields.deliveryDifferentPhone.required"
              :name="formFields.deliveryDifferentPhone.name"
              :maxlength="formFields.deliveryDifferentPhone.maxlength"
              :placeholder="formFields.deliveryDifferentPhone.placeholder"
              :validation="v$.deliveryDifferentPhone"
              @input="v$.deliveryDifferentPhone.$touch"
            />
          </div>
        </div>

        <LegoTextInput
          v-model.trim="state.deliveryDifferentEmail"
          :required="formFields.deliveryDifferentEmail.required"
          :name="formFields.deliveryDifferentEmail.name"
          :maxlength="formFields.deliveryDifferentEmail.maxlength"
          :placeholder="formFields.deliveryDifferentEmail.placeholder"
          input-type="email"
          :validation="v$.deliveryDifferentEmail"
          @input="v$.deliveryDifferentEmail.$touch"
        />
      </div>

      <div class="order-form-fields mb-6">
        <h3 class="subtitle mb-3">{{ formFields.deliveryNotes.label }}</h3>
        <LegoTextInput
          v-model.trim="state.deliveryNotes"
          :required="formFields.deliveryNotes.required"
          :name="formFields.deliveryNotes.name"
          :maxlength="formFields.deliveryNotes.maxlength"
          :placeholder="formFields.deliveryNotes.placeholder"
          input-type="textarea"
        />
      </div>
    </div>
    <div class="button-wrapper">
      <button
        v-if="!isLoading"
        class="button is-primary is-rounded"
        :disabled="disabled"
        @click="onNext"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { ref, watch, unref, reactive } from 'vue';
import useStep from '@/composables/useStep';
import useValidation from '@/composables/useValidation';

import LegoDatepickerInput from '@/components/controls/LegoDatepickerInput';
import LegoTextInput from '@/components/controls/LegoTextInput';
import LegoRadioGroup from '@/components/controls/LegoRadioGroup';
import LegoIcon from '@/components/base/LegoIcon';

import { ChoiceOfDeliveryOptionsAsObject } from '@/constants';

export default {
  components: { LegoIcon, LegoDatepickerInput, LegoTextInput, LegoRadioGroup },
  props: {
    disabled: {
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isLoading, formFields, unrefFormFields } = useStep();

    let state = reactive({
      requestedDeliveryDate: unrefFormFields.requestedDeliveryDate.value,
      contactPersonName: unrefFormFields.contactPersonName.value,
      contactPersonPhoneCountryCode:
        unrefFormFields.contactPersonPhoneCountryCode.value,
      contactPersonPhoneNumber: unrefFormFields.contactPersonPhoneNumber.value,
      contactPersonEmail: unrefFormFields.contactPersonEmail.value,
      choiceOfDelivery: unrefFormFields.choiceOfDelivery.value,
      deliveryNotes: unrefFormFields.deliveryNotes.value,

      // Additional dynamic fields
      deliveryDifferentCompanyName:
        unrefFormFields.deliveryDifferentCompanyName.value,
      deliveryDifferentAddress: unrefFormFields.deliveryDifferentAddress.value,
      deliveryDifferentZipCodeCity:
        unrefFormFields.deliveryDifferentZipCodeCity.value,
      deliveryDifferentCountry: unrefFormFields.deliveryDifferentCountry.value,
      deliveryDifferentContactPerson:
        unrefFormFields.deliveryDifferentContactPerson.value,
      deliveryDifferentPhoneCountryCode:
        unrefFormFields.deliveryDifferentPhoneCountryCode.value,
      deliveryDifferentPhone: unrefFormFields.deliveryDifferentPhone.value,
      deliveryDifferentEmail: unrefFormFields.deliveryDifferentEmail.value,
    });

    const { v$ } = useValidation(formFields, state);

    const showHiddenFields = ref(
      state.choiceOfDelivery ===
        ChoiceOfDeliveryOptionsAsObject.differentAddress.value
    );

    const REQUESTED_DELIVERY_DAYS = 13;
    const addDays = (date = new Date(), days) => {
      return date.setDate(date.getDate() + days);
    };
    const disabledBefore = addDays(new Date(), REQUESTED_DELIVERY_DAYS);
    const disabledRequestedDeliveryDates = (date) => date < disabledBefore;

    const additionalDeliveryFields = [
      'deliveryDifferentCompanyName',
      'deliveryDifferentAddress',
      'deliveryDifferentZipCodeCity',
      'deliveryDifferentCountry',
      'deliveryDifferentContactPerson',
      'deliveryDifferentPhone',
      'deliveryDifferentEmail',
    ];

    const onNext = () => {
      const payload = { ...unref(state) };
      if (!showHiddenFields.value) {
        additionalDeliveryFields.forEach((item) => delete payload[item]);
      }
      emit('onNext', {
        action: 'update',
        v$: unref(v$),
        payload,
      });
    };

    watch(state, (newValue) => {
      showHiddenFields.value =
        newValue.choiceOfDelivery ===
        ChoiceOfDeliveryOptionsAsObject.differentAddress.value;
    });

    return {
      v$,
      isLoading,
      formFields,
      state,
      showHiddenFields,
      onNext,
      disabledRequestedDeliveryDates,
    };
  },
};
</script>
