<template>
  <div class="lego-digital-boxes">
    <h2 v-if="boxes.length" class="title">Your Digiboxes:</h2>
    <ul class="columns is-desktop">
      <li
        v-for="{ id, label, statusCode, description } in boxes"
        :key="id"
        :style="{ 'max-width': '25rem' }"
        class="column"
      >
        <LegoInfoBox>
          <LegoBoxStatus
            :label="label"
            :code="statusCode"
            :is-centered="true"
            class="mb-4"
          />
          <p class="is-small-text">{{ description }}</p>
          <p v-if="showFaqButton(statusCode)" class="has-text-centered mt-4">
            <router-link class="button is-light is-rounded is-small" to="/faq"
              >Resolve (FAQ)</router-link
            >
          </p>
        </LegoInfoBox>
      </li>
    </ul>
  </div>
</template>

<script>
import { LegoBoxStates } from '@/constants';

import LegoBoxStatus from '@/components/base/LegoBoxStatus';
import LegoInfoBox from '@/components/base/LegoInfoBox';

export default {
  name: 'LegoDigitalBoxes',
  components: { LegoInfoBox, LegoBoxStatus },
  props: {
    boxes: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const showFaqButton = (statusCode) => {
      return statusCode === LegoBoxStates.OFFLINE_NEEDS_UPDATE;
    };

    return {
      showFaqButton,
    };
  },
};
</script>
