<template>
  <article>
    <h2 class="title mb-2">Swap unit procedure</h2>
    <p class="mb-5">
      The following swap steps should only be completed if instructed so after
      the resolution of support ticket you have created.
    </p>

    <LegoCollapsibleItem class="mb-5">
      <template #header>Swap procedure</template>
      <template #content>
        <div class="content">
          <p>
            Before you can set up your new Digibox, you will need to complete
            the following steps with the old Digibox:
          </p>

          <ol>
            <li>
              Unplug your old box from the power source and take out your
              internet cable from the computer.
            </li>
            <li>
              Take the old box down from the shelf and pack it in the shipping
              box in which it arrived.
            </li>
          </ol>

          <p>
            You are now ready to set up your new Digibox. Please follow the
            steps in the
            <router-link to="/installation-procedure">installation</router-link>
            procedure.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem :expanded="false" class="mb-5">
      <template #header> Swap procedure - Computer only </template>
      <template #content>
        <div class="content">
          <p>
            We highly recommend that you watch this video, before unplugging
            your old Digibox and setting up the new one.
          </p>

          <p>
            As supplement to the video, here is a short step-by-step
            description:
          </p>
          <ol>
            <li>Power down your Digibox</li>
            <li>
              Remove all cables from the computer. Internet, camera, HDMI and
              power
            </li>
            <li>Detach the strap and remove the computer</li>
            <li>Place the new computer and attach the strap</li>
            <li>Plug in all cables. Internet, camera, HDMI and power</li>
            <li>
              Turn on the Digibox and test the application with a supported LEGO
              box
            </li>
          </ol>

          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/834691202?h=b2af6c60d9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="DigiBox DB2 - Swap Computer"
            ></iframe>
          </div>

          <p>
            <i>*Note: Video has no audio</i>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>
  </article>
</template>

<script>
import LegoCollapsibleItem from '@/components/base/LegoCollapsibleItem';

export default {
  name: 'LegoSwapUnit',
  components: { LegoCollapsibleItem },
};
</script>
