<template>
  <div class="not-found-page">Route not found.</div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  name: 'NotFound',
  setup() {
    useMeta({
      title: 'Page not found',
    });
  },
};
</script>
