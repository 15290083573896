<template>
  <article class="lego-message message">
    <div class="message-header">
      <slot name="title" />
    </div>
    <div class="message-body">
      <slot name="body" />
    </div>
  </article>
</template>

<script>
export default {
  name: 'LegoMessage',
};
</script>

<style lang="scss"></style>
