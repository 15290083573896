import { ApiFields } from '@/constants';

export const StoreFormFields = {
  storeName: {
    name: 'field--store-name',
    placeholder: 'Store name',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_STORE_NAME,
    rules: ['required'],
    value: '',
  },
  storeAddress: {
    name: 'field--store-address',
    placeholder: 'Address',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_STORE_ADDRESS,
    rules: ['required'],
    value: '',
  },
  storeZipAndCity: {
    name: 'field--store-zip-code-and-city',
    placeholder: 'Zip code & City',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_ZIP_CODE_AND_CITY,
    rules: ['required'],
    value: '',
  },
  storeCountry: {
    name: 'field--store-country',
    placeholder: 'Country',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_COUNTRY,
    rules: ['required'],
    value: '',
  },
  storeManagerName: {
    name: 'field--store-manager-name',
    placeholder: 'Name',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_STORE_MANAGER_NAME,
    rules: ['required'],
    value: '',
  },
  storeManagerPhoneCountryCode: {
    name: 'field--store-manager-phone-country-code',
    placeholder: 'Country',
    maxlength: 3,
    required: true,
    rules: ['required'],
    value: '',
    excludeFromPayload: true,
  },
  storeManagerPhoneNumber: {
    name: 'field--store-manager-phone-number',
    placeholder: 'Phone number',
    maxlength: 15,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_STORE_MANAGER_PHONE_NUMBER,
    rules: ['required'],
    prependFrom: 'storeManagerPhoneCountryCode',
    value: '',
  },
  storeManagerEmail: {
    name: 'field--store-manager-email',
    placeholder: 'Email',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_STORE_STORE_MANAGER_EMAIL,
    rules: ['required', 'email'],
    value: '',
  },
};
