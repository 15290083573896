<template>
  <header class="lego-header">
    <router-link to="/">
      <img
        src="@/assets/images/digibox-logo.png"
        alt="Digibox logo"
        width="256"
        height="50"
      />
      <span class="is-sr-only"><slot /></span>
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'LegoHeader',
};
</script>

<style lang="scss">
@import '@/assets/styles/helpers';

.lego-header {
  &.is-menu {
    .title {
      @media (min-width: $lego-breakpoints-tablet) {
        max-width: 10rem;
        font-size: 1.8125rem;
      }
    }
  }
}
</style>
