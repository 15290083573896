<template>
  <div class="lego-box-status" :class="{ 'has-text-centered': isCentered }">
    <h5 class="subtitle mb-1">{{ label }}</h5>
    <p class="indicator-text" :class="[statusIndicator]">
      <b></b>
      <span class="has-text-weight-bold is-small-text" v-html="statusText" />
    </p>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';
import { getBoxStatusClassIndicator, getBoxStatusLabel } from '@/utils';

export default {
  name: 'LegoBoxStatus',
  props: {
    label: {
      type: String,
      required: true,
    },
    code: {
      type: [String, Number],
      required: true,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { code } = toRefs(props);
    const statusIndicator = computed(() =>
      getBoxStatusClassIndicator(+code.value)
    );
    const statusText = computed(() => getBoxStatusLabel(+code.value));

    return {
      statusText,
      statusIndicator,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/helpers';

.lego-box-status {
  .indicator-text {
    > b {
      display: inline-block;
      border-radius: 50%;
      margin: 0 0.5rem 0 0;
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
      background: $lego-indicator-default-color;
    }

    i {
      font-style: normal;
    }
  }

  .online {
    color: $lego-indicator-online-color;

    b {
      background: $lego-indicator-online-color;
    }

    &--needs-update {
      b {
        background: $lego-indicator-needs-update-color;
      }

      i {
        color: $lego-indicator-needs-update-color;
      }
    }
  }

  .offline {
    color: $lego-indicator-offline-color;

    b {
      background: $lego-indicator-offline-color;
    }

    i {
      color: $lego-indicator-online-color;
    }

    &--needs-update {
      color: $lego-indicator-needs-update-color;

      b {
        background: $lego-indicator-needs-update-color;
      }

      i {
        color: $lego-indicator-needs-update-color;
      }
    }
  }
}
</style>
