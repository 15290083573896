import { preparePayloadGeneric, determineBoxStatusCode } from '@/utils';
import { determineBoxDescription } from '../utils';

export default {
  authUser(state) {
    return state.authUser;
  },
  acceptedTermsAndConditions(state) {
    return state.acceptedTermsAndConditions;
  },
  isActiveApiAction(state) {
    return (actionKey) => state.activeApiActions.includes(actionKey);
  },
  isLoading(state) {
    return state.isLoading;
  },
  orderSections(state) {
    return state.orderSections;
  },
  orderFormFields(state) {
    return state.orderSections[state.currentOrderStep - 1].formFields;
  },
  currentOrderStep(state) {
    return state.currentOrderStep;
  },
  isFinalOrderStep(state) {
    return state.currentOrderStep === 4;
  },
  orderNumber(state) {
    return state.orderNumber;
  },
  allDataForOrderApi(state) {
    return preparePayloadGeneric(state.orderSections);
  },
  digiboxes(state) {
    return state.digiboxes.map((item) => {
      const statusCode = determineBoxStatusCode(state.authUser);

      return {
        id: item.hostname,
        label: `Digibox ${item.hostname}`,
        dbVersion: item.semver,
        statusCode: statusCode,
        description: determineBoxDescription(statusCode),
      };
    });
  },

  // Support
  supportSections(state) {
    return state.supportSections;
  },
  supportFormFields(state) {
    return state.supportSections[state.currentSupportStep - 1].formFields;
  },
  currentSupportStep(state) {
    return state.currentSupportStep;
  },
  allDataForSupportApi(state) {
    return preparePayloadGeneric(state.supportSections);
  },
};
