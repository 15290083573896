<template>
  <div class="lego-step-04 mb-6">
    <div class="lego-rectangle-shadow" />
    <LegoSuccessNotification class="mb-6">
      <template #title> Thank you! </template>
      <template #content>
        <p class="has-text-centered">
          Our staff will now process your order, and contact you regarding the
          delivery of your Digibox.
        </p>
        <br />
        <p class="has-text-centered">Your request number is:</p>
        <div v-if="orderNumber" class="order-number">
          {{ orderNumber }}
        </div>
      </template>
    </LegoSuccessNotification>
    <div class="button-wrapper">
      <button class="button is-light is-rounded" @click="backToStart">
        Back to start
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import LegoSuccessNotification from '@/components/order/notifications/LegoSuccessNotification';
import { computed } from 'vue';

export default {
  components: { LegoSuccessNotification },
  setup() {
    const store = useStore();
    const resetOrderForm = () => store.dispatch('resetOrderForm');
    const orderNumber = computed(() => store.getters.orderNumber);

    const backToStart = () => {
      resetOrderForm();
    };

    return {
      orderNumber,
      backToStart,
    };
  },
};
</script>
