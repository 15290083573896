<template>
  <div class="lego-back-button mb-4">
    <button class="button is-text" :disabled="disabled" @click="back">
      <LegoIcon icon="fa-arrow-left" />
      <span>Back</span>
    </button>
  </div>
</template>

<script>
import LegoIcon from './LegoIcon';
import { mapActions } from 'vuex';

export default {
  name: 'LegoBackBtn',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { LegoIcon },
  methods: {
    ...mapActions({
      goToOrderStep: 'goToOrderStep',
    }),
    back() {
      this.$emit('onBack');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.lego-back-button {
  .button {
    &.is-text {
      color: $lego-icon-color;
      background: transparent;
      font-size: 1.1rem;
      text-decoration: none;
      padding-left: 0.3rem;
    }
  }
}
</style>
