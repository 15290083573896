<template>
  <div class="radio-group" role="group" :aria-labelledby="`${id}-label`">
    <p v-if="label" :id="`${id}-label`" class="label">
      <span>
        {{ label }}
        <span v-if="required" class="required">*</span>
      </span>
    </p>
    <div class="control">
      <LegoRadioInput
        v-for="(option, key) in options"
        :id="`${id}-${key}`"
        :key="key"
        :value="option.value"
        :label="option.label"
        :name="name"
        :required="required"
        :checked="option.value === selected"
        @input="onInput"
      />
    </div>
  </div>
</template>

<script>
import LegoRadioInput from '@/components/controls/LegoRadioInput';
import { computed, ref, toRefs, watch } from 'vue';

export default {
  name: 'LegoRadioGroup',
  components: {
    LegoRadioInput,
  },
  props: {
    modelValue: {
      type: [Object, String, Number],
      default: null,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
      note: 'Associated label',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);
    let selected = ref(props.modelValue);
    const id = computed(() => `${props.name}-id`);

    watch(modelValue, (newValue) => {
      selected.value = newValue;
    });

    const onInput = (e) => {
      selected.value = e;
      emit('update:modelValue', selected.value);
    };

    return {
      id,
      selected,
      onInput,
    };
  },
};
</script>
