export const ApiSupportFields = {
  // Welcome
  STEP_WELCOME_AGREE: 'agree', // @revise: don't need?

  // Open Ticket
  STEP_OPEN_STORE_NAME: 'store_name',
  STEP_OPEN_CONTACT_INFORMATION: 'email',
  STEP_OPEN_DESCRIPTION: 'description',
  STEP_OPEN_ATTEMPTS: 'attempts',

  // @todo: file upload support ?
  STEP_OPEN_NEW_IMAGE: 'new_support_image',
};
