<template>
  <div class="lego-order-helper">
    <LegoInfoBox>
      <div class="columns">
        <div class="column">
          <h3 class="title is-size-4 mb-4">Order your Digibox</h3>
          <p class="mb-4">
            You can order your Digibox here, we have made it as easy as
            possible! Just fill in the details in this form, and we will deliver
            it as soon as possible, once everything is in place.
          </p>
          <router-link
            to="/order"
            class="button is-primary is-rounded is-flex-mobile"
            >Start Order</router-link
          >
        </div>
        <div class="column">
          <figure class="image">
            <img
              src="@/assets/images/Digibox-order.png"
              style="max-width: 270px"
              alt="Order your digibox"
            />
          </figure>
        </div>
      </div>
    </LegoInfoBox>
  </div>
</template>

<script>
import LegoInfoBox from '@/components/base/LegoInfoBox';

export default {
  name: 'LegoOrderHelper',
  components: { LegoInfoBox },
};
</script>
