import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const plugins = [
  createPersistedState({
    storage: window.sessionStorage,
    key: 'lego_manager',
    paths: [
      // 'authUser',
      'currentOrderStep',
      'orderSections',
      'orderNumber',
      'acceptedTermsAndConditions',
    ],
  }),
];

export default createStore({
  state,
  mutations,
  getters,
  actions,
  plugins: plugins,
});
