import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/styles/main.scss';
import 'vue2-datepicker/index.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import VueMatomo from 'vue-matomo';

import DatePicker from 'vue2-datepicker';

createApp(App)
  .use(store)
  .use(router)
  .use(VueMatomo, {
    host: 'https://aws.proreact.dk/tracking/',
    siteId: 6,
    router,
  })
  .use(createMetaManager())
  .use(DatePicker)
  .mount('#app');
