import { ApiFields } from '@/constants';

export const InvoiceFormFields = {
  purchaseResponsibleName: {
    name: 'field--invoice-responsible-name',
    placeholder: 'Name',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_RESPONSIBLE_NAME,
    rules: ['required'],
    value: '',
  },
  purchaseResponsiblePhoneCountryCode: {
    name: 'field--invoice-responsible-phone-country',
    placeholder: 'Country',
    maxlength: 3,
    required: true,
    rules: ['required'],
    value: '',
    excludeFromPayload: true,
  },
  purchaseResponsiblePhoneNumber: {
    name: 'field--invoice-responsible-phone-number',
    placeholder: 'Phone number',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_RESPONSIBLE_PHONE_NUMBER,
    rules: ['required'],
    prependFrom: 'purchaseResponsiblePhoneCountryCode',
    value: '',
  },
  purchaseResponsibleEmail: {
    name: 'field--invoice-responsible-email',
    placeholder: 'E-mail',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_RESPONSIBLE_EMAIL,
    rules: ['required', 'email'],
    value: '',
  },
  companyName: {
    name: 'field--invoice-company-name',
    placeholder: 'Company Name',
    maxlength: 255,
    required: false,
    apiFieldName: ApiFields.STEP_INVOICE_COMPANY_NAME,
    rules: [],
    value: '',
  },
  vatNumber: {
    name: 'field--invoice-vat-number',
    placeholder: 'VAT number',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_VAT_NUMBER,
    rules: ['required'],
    value: '',
  },
  invoicingAddress: {
    name: 'field--invoice-invoicing-address',
    placeholder: 'Invoicing address',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_INVOICING_ADDRESS,
    rules: ['required'],
    value: '',
  },
  invoiceZipAndCity: {
    name: 'field--invoice-zip-code-and-city',
    placeholder: 'Zip code & City',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_ZIP_CODE_AND_CITY,
    rules: ['required'],
    value: '',
  },
  invoiceCountry: {
    name: 'field--invoice-country',
    placeholder: 'Country',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_COUNTRY,
    rules: ['required'],
    value: '',
  },
  emailForInvoices: {
    name: 'field--invoice-email-for-invoices',
    placeholder: 'E-mail for invoices',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_INVOICE_EMAIL_FOR_INVOICES,
    rules: ['required', 'email'],
    value: '',
  },
};
