<template>
  <div class="lego-step-01">
    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">Store:</h3>
      <LegoTextInput
        v-model.trim="state.storeName"
        :required="formFields.storeName.required"
        :name="formFields.storeName.name"
        :maxlength="formFields.storeName.maxlength"
        :placeholder="formFields.storeName.placeholder"
        :validation="v$.storeName"
        @input="v$.storeName.$touch"
      />
      <LegoTextInput
        v-model.trim="state.storeAddress"
        :required="formFields.storeAddress.required"
        :name="formFields.storeAddress.name"
        :maxlength="formFields.storeAddress.maxlength"
        :placeholder="formFields.storeAddress.placeholder"
        :validation="v$.storeAddress"
        @input="v$.storeAddress.$touch"
      />
      <LegoTextInput
        v-model.trim="state.storeZipAndCity"
        :required="formFields.storeZipAndCity.required"
        :name="formFields.storeZipAndCity.name"
        :maxlength="formFields.storeZipAndCity.maxlength"
        :placeholder="formFields.storeZipAndCity.placeholder"
        :validation="v$.storeZipAndCity"
        @input="v$.storeZipAndCity.$touch"
      />
      <LegoTextInput
        v-model.trim="state.storeCountry"
        :required="formFields.storeCountry.required"
        :name="formFields.storeCountry.name"
        :maxlength="formFields.storeCountry.maxlength"
        :placeholder="formFields.storeCountry.placeholder"
        :validation="v$.storeCountry"
        @input="v$.storeCountry.$touch"
      />
    </div>

    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">Store manager:</h3>
      <LegoTextInput
        v-model.trim="state.storeManagerName"
        :required="formFields.storeManagerName.required"
        :name="formFields.storeManagerName.name"
        :maxlength="formFields.storeManagerName.maxlength"
        :placeholder="formFields.storeManagerName.placeholder"
        :validation="v$.storeManagerName"
        class="mb-5"
        @input="v$.storeManagerName.$touch"
      />
      <div class="columns is-vcentered is-variable is-1 is-mobile">
        <div class="column is-narrow">
          <LegoIcon icon="fa-plus" namespace="fas" class="light-icon" />
        </div>
        <div class="column is-4">
          <LegoTextInput
            v-model.trim="state.storeManagerPhoneCountryCode"
            :required="formFields.storeManagerPhoneCountryCode.required"
            :name="formFields.storeManagerPhoneCountryCode.name"
            :maxlength="formFields.storeManagerPhoneCountryCode.maxlength"
            :placeholder="formFields.storeManagerPhoneCountryCode.placeholder"
            :validation="v$.storeManagerPhoneCountryCode"
            @input="v$.storeManagerPhoneCountryCode.$touch"
          />
        </div>
        <div class="column">
          <LegoTextInput
            v-model.trim="state.storeManagerPhoneNumber"
            :required="formFields.storeManagerPhoneNumber.required"
            :name="formFields.storeManagerPhoneNumber.name"
            :maxlength="formFields.storeManagerPhoneNumber.maxlength"
            :placeholder="formFields.storeManagerPhoneNumber.placeholder"
            :validation="v$.storeManagerPhoneNumber"
            @input="v$.storeManagerPhoneNumber.$touch"
          />
        </div>
      </div>
      <LegoTextInput
        v-model.trim="state.storeManagerEmail"
        :required="formFields.storeManagerEmail.required"
        :name="formFields.storeManagerEmail.name"
        :maxlength="formFields.storeManagerEmail.maxlength"
        :placeholder="formFields.storeManagerEmail.placeholder"
        input-type="email"
        :validation="v$.storeManagerEmail"
        @input="v$.storeManagerEmail.$touch"
      />
    </div>
    <div class="button-wrapper">
      <button
        v-if="!isLoading"
        class="button is-primary is-rounded"
        :disabled="disabled"
        @click="onNext"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, unref } from 'vue';

import LegoTextInput from '@/components/controls/LegoTextInput';
import useStep from '@/composables/useStep';
import useValidation from '@/composables/useValidation';
import LegoIcon from '@/components/base/LegoIcon';

export default {
  components: { LegoIcon, LegoTextInput },
  props: {
    disabled: {
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isLoading, formFields, unrefFormFields } = useStep();

    let state = reactive({
      storeName: unrefFormFields.storeName.value,
      storeAddress: unrefFormFields.storeAddress.value,
      storeZipAndCity: unrefFormFields.storeZipAndCity.value,
      storeCountry: unrefFormFields.storeCountry.value,
      storeManagerName: unrefFormFields.storeManagerName.value,
      storeManagerPhoneCountryCode:
        unrefFormFields.storeManagerPhoneCountryCode.value,
      storeManagerPhoneNumber: unrefFormFields.storeManagerPhoneNumber.value,
      storeManagerEmail: unrefFormFields.storeManagerEmail.value,
    });

    const { v$ } = useValidation(formFields, state);

    const onNext = () => {
      emit('onNext', {
        action: 'update',
        v$: unref(v$),
        payload: { ...unref(state) },
      });
    };

    return {
      v$,
      isLoading,
      formFields,
      state,
      onNext,
    };
  },
};
</script>
