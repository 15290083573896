<template>
  <div class="order-page">
    <LegoOrderMain />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import LegoOrderMain from '@/components/order/LegoOrderMain';

export default {
  name: 'Order',
  components: { LegoOrderMain },
  setup() {
    useMeta({
      title: 'Order',
    });
  },
};
</script>
