<template>
  <form novalidate @submit.prevent="doSearch">
    <div class="columns is-variable is-1">
      <div class="column">
        <LegoTextInput
          v-model="searchText"
          placeholder="Looking for a specific SKU?"
        />
      </div>
      <div class="column is-narrow">
        <button class="button is-primary is-rounded">Search</button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue';
import LegoTextInput from '@/components/controls/LegoTextInput';

export default {
  name: 'LegoSearchSku',
  components: { LegoTextInput },
  emits: ['search'],
  setup(props, context) {
    const searchText = ref('');

    const doSearch = () => {
      context.emit('search', searchText.value);
    };

    return {
      searchText,
      doSearch,
    };
  },
};
</script>
