import {
  StoreFormFields,
  DeliveryFormFields,
  InvoiceFormFields,
} from './form-config';

export const OrderSections = [
  {
    name: 'LegoStepStore',
    formFields: { ...StoreFormFields },
  },
  {
    name: 'LegoStepDelivery',
    formFields: { ...DeliveryFormFields },
  },
  {
    name: 'LegoStepInvoice',
    formFields: { ...InvoiceFormFields },
  },
  {
    name: 'LegoStepReceipt',
  },
];
