<template>
  <div class="static-page login-unknown-page">
    <LegoHeader class="mb-6">{{ appTitle }}</LegoHeader>
    <div class="columns">
      <div class="column is-5">
        <div v-if="!isSent">
          <h3 class="subtitle">Unknown e-mail or user</h3>
          <p class="is-small-text mb-5">
            We don’t seem to have an account registered with the entered
            information. Please tell us a bit more about yourself, and we willl
            contact you to give you access.
          </p>
          <form class="main-form-wrap mb-6" @submit.prevent>
            <LegoTextInput
              v-model.trim="state.name"
              :required="formFields.name.required"
              :maxlength="formFields.name.maxlength"
              :name="formFields.name.name"
              :placeholder="formFields.name.placeholder"
              :validation="v$.name"
              @input="v$.name.$touch"
              class="mb-5"
            />

            <LegoTextInput
              v-model.trim="state.email"
              :required="formFields.email.required"
              :maxlength="formFields.email.maxlength"
              :name="formFields.email.name"
              :placeholder="formFields.email.placeholder"
              :validation="v$.email"
              @input="v$.email.$touch"
              class="mb-5"
            />

            <LegoTextInput
              v-model.trim="state.store"
              :required="formFields.store.required"
              :maxlength="formFields.store.maxlength"
              :name="formFields.store.name"
              :placeholder="formFields.store.placeholder"
              :validation="v$.store"
              @input="v$.store.$touch"
              class="mb-5"
            />

            <LegoTextInput
              v-model.trim="state.cityAndCountry"
              :required="formFields.cityAndCountry.required"
              :maxlength="formFields.cityAndCountry.maxlength"
              :name="formFields.cityAndCountry.name"
              :placeholder="formFields.cityAndCountry.placeholder"
              :validation="v$.cityAndCountry"
              @input="v$.cityAndCountry.$touch"
              class="mb-5"
            />

            <LegoTextInput
              v-model.trim="state.phone"
              :required="formFields.phone.required"
              :maxlength="formFields.phone.maxlength"
              :name="formFields.phone.name"
              :placeholder="formFields.phone.placeholder"
              :validation="v$.phone"
              @input="v$.phone.$touch"
              class="mb-5"
            />

            <p class="has-text-centered mb-4">
              <button
                :disabled="isLoading"
                class="button is-primary is-rounded"
                @click="submitLoginUnknown"
              >
                Submit
              </button>
            </p>
          </form>
        </div>
        <div v-else>
          <p class="has-text-centered mb-4">
            <img
              src="@/assets/images/forgot-password-sent.png"
              height="190"
              width="190"
            />
          </p>
          <h3 class="subtitle">We will contact you shortly</h3>
          <p class="is-small-text is-privacy-text mb-5">
            Our support staff have received your inquiry, and will provide you
            with login information within two days.
          </p>
          <p class="has-text-centered">
            <router-link to="/login" class="button is-light is-rounded">
              Close
            </router-link>
          </p>
        </div>
      </div>
      <div class="column is-narrow">
        <LegoNeedAssistance />
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import useValidation from '@/composables/useValidation';

import LegoHeader from '@/components/base/LegoHeader';
import LegoNeedAssistance from '@/components/order/LegoNeedAssistance';
import LegoTextInput from '@/components/controls/LegoTextInput';

import { LoginUnknownFormFields } from '@/constants/form-config';

export default {
  name: 'LoginUnknown',
  components: {
    LegoTextInput,
    LegoHeader,
    LegoNeedAssistance,
  },
  setup() {
    useMeta({
      title: 'Unknown e-mail or user',
    });

    const store = useStore();
    let isSent = ref(false);

    let state = reactive({
      name: '',
      email: '',
      store: '',
      cityAndCountry: '',
      phone: '',
    });

    let formFields = reactive({
      name: LoginUnknownFormFields.name,
      email: LoginUnknownFormFields.email,
      store: LoginUnknownFormFields.store,
      cityAndCountry: LoginUnknownFormFields.cityAndCountry,
      phone: LoginUnknownFormFields.phone,
    });

    const { v$ } = useValidation(formFields, state);

    const isLoading = computed(() => store.getters.isLoading);

    const doLoginUnknown = (payload) =>
      store.dispatch('doLoginUnknown', payload);

    const submitLoginUnknown = async () => {
      if (v$.value.$invalid) {
        return;
      }

      const payload = {
        name: state.name,
        email: state.email,
        store: state.store,
        cityAndCountry: state.cityAndCountry,
        phone: state.phone,
      };

      const result = await doLoginUnknown(payload);
      if (result.success) {
        isSent.value = true;
      }
    };

    return {
      state,
      formFields,
      v$,
      isLoading,
      submitLoginUnknown,
      isSent,
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
