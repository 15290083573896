<template>
  <article class="static-page home-page">
    <div class="content">
      <div class="columns mb-5">
        <div class="column is-half">
          <h1 class="title">The LEGO® Digibox Experience</h1>

          <div class="mb-6">
            <p>
              The Digibox gives your customers a unique physical-digital
              experience with our products. By holding a LEGO® box up in front
              of the Digibox, a digital 3D animation of the finished product is
              triggered on the screen. It appears on top of the physical box the
              customer is standing with, together with sound and animation. See
              the LEGO® Digibox in action in the video “LEGO® Digibox –how it
              works”
            </p>
          </div>

          <div class="mb-6">
            <h4 class="subtitle mb-2">
              SKU – assortment updated automatically
            </h4>
            <p>
              Each quarter the sku assortment in the LEGO® Digibox is updated
              with the latest novelties. Your LEGO® Digibox gets automatically
              updated – provided it is connected with the internet.
            </p>
          </div>

          <div>
            <h4 class="subtitle mb-2">
              Ensure the best LEGO® Digibox Experience with 5 Golden Tips for
              the store!
            </h4>
            <p>
              We have collected 5 golden tips for the store staff to ensure an
              optimal LEGO® Digibox-experience for shoppers. Make sure the store
              staff see this video and have prepared the LEGO® Digibox, the
              internet connection and right store surroundings. See the tips in
              the video “LEGO® Digibox Golden Tips”
            </p>
          </div>
        </div>

        <div class="column is-half">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/646614908?h=46f01ac5cd&portrait=0&byline=0"
            width="100%"
            height="280"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            class="mb-2"
          ></iframe>
          <p class="has-text-centered is-italic">
            “LEGO® Digibox – how it works”
          </p>
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/1006171411?h=ce7c7cba71&portrait=0&byline=0"
            width="100%"
            height="280"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            class="mb-2"
          ></iframe>
          <p class="has-text-centered is-italic">“LEGO® Digibox Golden Tips”</p>
        </div>
      </div>

      <LegoOrderHelper class="mb-6" />
    </div>
  </article>
</template>

<script>
import { useMeta } from 'vue-meta';
import LegoOrderHelper from '@/components/order/LegoOrderHelper';

export default {
  name: 'Home',
  components: { LegoOrderHelper },
  setup() {
    useMeta({
      title: 'Home',
    });
  },
};
</script>
