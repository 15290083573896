<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | Digibox` : `Digibox`
    }}</template>
  </metainfo>
  <div class="app-wrapper">
    <div class="columns is-gapless">
      <div v-if="!hideMenu" class="sidebar column app-sidebar">
        <LegoMenu />
      </div>
      <div class="column">
        <div class="container main-container py-4 px-5">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { getCookie, storeLoaded } from '@/utils';

import LegoMenu from './components/base/LegoMenu';

export default {
  components: { LegoMenu },
  setup() {
    const route = useRoute();
    const store = useStore();

    const hideMenuFor = [
      'Login',
      'LoginUnknown',
      'ForgotPassword',
      'NewPassword',
    ];

    const hideMenu = computed(() => hideMenuFor.includes(route.name));
    const authUser = computed(() => store.getters.authUser);
    const digiboxes = computed(() => store.getters.digiboxes);

    onMounted(async () => {
      const tokenId = getCookie('Token');
      if (tokenId) {
        store.dispatch('checkLogin');
      }
    });

    return {
      hideMenu,
      authUser,
      digiboxes,
      store,
    };
  },
  watch: {
    digiboxes() {
      this.initFreshdeskWidget();
    },
  },
  mounted() {
    this.initFreshdeskWidget();
  },
  methods: {
    initFreshdeskWidget() {
      window.fwSettings = {
        widget_id: 103000004522,
        locale: 'en',
      };
      // eslint-disable-next-line prettier/prettier
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()

      this.addFreshdeskScript();
    },
    addFreshdeskScript() {
      let freshdeskScript = document.createElement('script');
      freshdeskScript.setAttribute(
        'src',
        'https://euc-widget.freshworks.com/widgets/103000004522.js'
      );
      document.head.appendChild(freshdeskScript);
      freshdeskScript.onload = async () => {
        // Ensure store is loaded
        await storeLoaded(this.store);

        window.FreshworksWidget('hide', 'launcher');

        if (this.authUser && this.digiboxes.length) {
          window.FreshworksWidget('prefill', 'ticketForm', {
            subject: `${this.digiboxes[0].label} - I'm having issues with...`,
            description: `\n\n\n\n\n-------\nUser email: ${this.authUser.email}\nInstallation ID: ${this.authUser.installation_id}\nHostname: ${this.digiboxes[0].id}\nDB Version: ${this.digiboxes[0].dbVersion}`,
          });
        }
      };
    },
  },
};
</script>
