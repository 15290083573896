<template>
  <div class="card lego-success-notification">
    <div class="card-header">
      <div class="columns is-vcentered is-mobile">
        <div class="column is-narrow">
          <LegoIcon class="success-icon" icon="fa-check-circle" />
        </div>
        <div class="column"><slot name="title" /></div>
      </div>
    </div>
    <div class="card-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import LegoIcon from '@/components/base/LegoIcon';

export default {
  name: 'LegoSuccessNotification',
  components: { LegoIcon },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.lego-success-notification {
  background: #fff;
  border-radius: 0;
  margin: 0 auto;
  width: 18rem;
}
.card-header {
  background: $lego-success-notification-header-color;
  border-radius: 0 !important;
  color: #fff;
  padding: 1rem 1.5rem 0.8rem;
  font-size: 1.5rem;
  font-weight: 700;
  border-bottom: 6px dotted #5f8800;
}
.success-icon {
  color: #394b08;
  font-size: 2.75rem;
  height: auto;
  width: auto;
}
.card-content {
  font-size: $lego-notification-font-size;
}
</style>
