<template>
  <div class="lego-installation-instructions-wrapper">
    <LegoInfoBox>
      <h3 class="has-text-centered subtitle mt-3">Installation instructions</h3>

      <p class="mb-6 is-small-text">
        If you’ve recieved your Digibox, and need instructions for installation.
      </p>

      <p class="has-text-centered">
        <router-link
          class="button is-light is-rounded mb-5"
          :to="{ name: 'InstallationProcedure' }"
          >Instructions</router-link
        >
      </p>
    </LegoInfoBox>
  </div>
</template>

<script>
import LegoInfoBox from '@/components/base/LegoInfoBox';

export default {
  name: 'LegoInstallationInstructions',
  components: { LegoInfoBox },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.lego-installation-instructions-wrapper {
  margin: 1rem;

  @media (min-width: $lego-breakpoints-tablet) {
    margin: 2.4rem 2rem;

    .lego-info-box {
      max-width: 20rem;
      margin: 0 auto;
    }
  }
}
</style>
