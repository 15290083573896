export const ChoiceOfDeliveryOptionsAsObject = {
  sameAsStore: {
    label: 'Same as store address',
    value: 'Same as store address',
  },
  differentAddress: {
    label: 'Different address',
    value: 'Different address',
  },
};

export const ChoiceOfDeliveryOptions = [
  ...Object.values(ChoiceOfDeliveryOptionsAsObject),
];
