<template>
  <div class="lego-privacy-policy">
    <h3 class="subtitle mt-3">Privacy Policy:</h3>
    <p class="is-small-text is-privacy-text mb-5">
      All information registered on this site is only used for the purpose of
      being able to support the
      <span class="is-danger-text">LEGO®</span> stores. IBG ProReact does not
      share any personal information with any third party. We regularly check
      our security in order to make sure that all data is stored as it should
      be. Personal information is used solely to enable the support service
      provided by IBG ProReact. Only dedicated employees are able to login to
      this site.
    </p>

    <p class="is-small-text mb-5">
      The credentials needed to access this FAQ / Support site are included in
      the original shipment for your Digibox installation. If you have lost
      these credentials, please contact:
      <a
        class="is-text-decoration-underline"
        :href="`mailto:${supportEmail}`"
        >{{ supportEmail }}</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'LegoPrivacyPolicy',
  setup() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
    };
  },
};
</script>
