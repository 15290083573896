<template>
  <div class="lego-step-03">
    <div class="order-form-fields mb-6">
      <h3 class="subtitle">LEGO purchase responsible / LCS KAM:</h3>
      <LegoTextInput
        v-model.trim="state.purchaseResponsibleName"
        :required="formFields.purchaseResponsibleName.required"
        :name="formFields.purchaseResponsibleName.name"
        :maxlength="formFields.purchaseResponsibleName.maxlength"
        :placeholder="formFields.purchaseResponsibleName.placeholder"
        :validation="v$.purchaseResponsibleName"
        @input="v$.purchaseResponsibleName.$touch"
      />

      <div class="columns is-vcentered is-variable is-1 is-mobile">
        <div class="column is-narrow">
          <LegoIcon icon="fa-plus" namespace="fas" class="light-icon" />
        </div>
        <div class="column is-4">
          <LegoTextInput
            v-model.trim="state.purchaseResponsiblePhoneCountryCode"
            :required="formFields.purchaseResponsiblePhoneCountryCode.required"
            :name="formFields.purchaseResponsiblePhoneCountryCode.name"
            :maxlength="
              formFields.purchaseResponsiblePhoneCountryCode.maxlength
            "
            :placeholder="
              formFields.purchaseResponsiblePhoneCountryCode.placeholder
            "
            :validation="v$.purchaseResponsiblePhoneCountryCode"
            @input="v$.purchaseResponsiblePhoneCountryCode.$touch"
          />
        </div>
        <div class="column">
          <LegoTextInput
            v-model.trim="state.purchaseResponsiblePhoneNumber"
            :required="formFields.purchaseResponsiblePhoneNumber.required"
            :name="formFields.purchaseResponsiblePhoneNumber.name"
            :maxlength="formFields.purchaseResponsiblePhoneNumber.maxlength"
            :placeholder="formFields.purchaseResponsiblePhoneNumber.placeholder"
            :validation="v$.purchaseResponsiblePhoneNumber"
            @input="v$.purchaseResponsiblePhoneNumber.$touch"
          />
        </div>
      </div>

      <LegoTextInput
        v-model.trim="state.purchaseResponsibleEmail"
        :required="formFields.purchaseResponsibleEmail.required"
        :name="formFields.purchaseResponsibleEmail.name"
        :maxlength="formFields.purchaseResponsibleEmail.maxlength"
        :placeholder="formFields.purchaseResponsibleEmail.placeholder"
        input-type="email"
        :validation="v$.purchaseResponsibleEmail"
        @input="v$.purchaseResponsibleEmail.$touch"
      />
    </div>

    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">Invoicing details:</h3>
      <LegoTextInput
        v-model.trim="state.companyName"
        :required="formFields.companyName.required"
        :name="formFields.companyName.name"
        :maxlength="formFields.companyName.maxlength"
        :placeholder="formFields.companyName.placeholder"
        :validation="v$.companyName"
        @input="v$.companyName.$touch"
      />
      <LegoTextInput
        v-model.trim="state.vatNumber"
        :required="formFields.vatNumber.required"
        :name="formFields.vatNumber.name"
        :maxlength="formFields.vatNumber.maxlength"
        :placeholder="formFields.vatNumber.placeholder"
        :validation="v$.vatNumber"
        @input="v$.vatNumber.$touch"
      />
      <LegoTextInput
        v-model.trim="state.invoicingAddress"
        :required="formFields.invoicingAddress.required"
        :name="formFields.invoicingAddress.name"
        :maxlength="formFields.invoicingAddress.maxlength"
        :placeholder="formFields.invoicingAddress.placeholder"
        :validation="v$.invoicingAddress"
        @input="v$.invoicingAddress.$touch"
      />
      <LegoTextInput
        v-model.trim="state.invoiceZipAndCity"
        :required="formFields.invoiceZipAndCity.required"
        :name="formFields.invoiceZipAndCity.name"
        :maxlength="formFields.invoiceZipAndCity.maxlength"
        :placeholder="formFields.invoiceZipAndCity.placeholder"
        :validation="v$.invoiceZipAndCity"
        @input="v$.invoiceZipAndCity.$touch"
      />
      <LegoTextInput
        v-model.trim="state.invoiceCountry"
        :required="formFields.invoiceCountry.required"
        :name="formFields.invoiceCountry.name"
        :maxlength="formFields.invoiceCountry.maxlength"
        :placeholder="formFields.invoiceCountry.placeholder"
        :validation="v$.invoiceCountry"
        @input="v$.invoiceCountry.$touch"
      />
      <LegoTextInput
        v-model.trim="state.emailForInvoices"
        :required="formFields.emailForInvoices.required"
        :name="formFields.emailForInvoices.name"
        :maxlength="formFields.emailForInvoices.maxlength"
        :placeholder="formFields.emailForInvoices.placeholder"
        input-type="email"
        :validation="v$.emailForInvoices"
        @input="v$.emailForInvoices.$touch"
      />
    </div>
    <div class="button-wrapper">
      <button
        v-if="!isLoading"
        class="button is-primary is-rounded"
        :disabled="disabled"
        @click="onNext"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, unref } from 'vue';
import useStep from '@/composables/useStep';
import useValidation from '@/composables/useValidation';

import LegoTextInput from '@/components/controls/LegoTextInput';
import LegoIcon from '@/components/base/LegoIcon';

export default {
  components: { LegoIcon, LegoTextInput },
  props: {
    disabled: {
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isLoading, formFields, unrefFormFields } = useStep();

    let state = reactive({
      purchaseResponsibleName: unrefFormFields.purchaseResponsibleName.value,
      purchaseResponsiblePhoneCountryCode:
        unrefFormFields.purchaseResponsiblePhoneCountryCode.value,
      purchaseResponsiblePhoneNumber:
        unrefFormFields.purchaseResponsiblePhoneNumber.value,
      purchaseResponsibleEmail: unrefFormFields.purchaseResponsibleEmail.value,
      companyName: unrefFormFields.companyName.value,
      vatNumber: unrefFormFields.vatNumber.value,
      invoicingAddress: unrefFormFields.invoicingAddress.value,
      emailForInvoices: unrefFormFields.emailForInvoices.value,
    });

    const { v$ } = useValidation(formFields, state);

    const onNext = () => {
      emit('onNext', {
        action: 'save',
        v$: unref(v$),
        payload: { ...unref(state) },
      });
    };

    return {
      v$,
      isLoading,
      formFields,
      state,
      onNext,
    };
  },
};
</script>
