<template>
  <div class="static-page requirements-page">
    <h1 class="title">Requirements</h1>
    <LegoCollapsibleItem
      v-for="({ title, data, isExpanded }, key) in items"
      :key="key"
      :expanded="isExpanded"
      class="mb-5"
      @click="$matomo.trackEvent('Requirements', 'Item click', title)"
    >
      <template #header> {{ title }} </template>
      <template #content>
        <div v-html="data" />
      </template>
    </LegoCollapsibleItem>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

import LegoCollapsibleItem from '@/components/base/LegoCollapsibleItem';

export default {
  name: 'Requirements',
  components: { LegoCollapsibleItem },
  setup() {
    useMeta({
      title: 'Requirements',
    });

    const items = [
      {
        title: 'Power',
        data:
          '<p>The Digibox must be connected to a fixed power source that does not automatically turn off outside shopping hours.</p><br/><p>We supply the Digibox to fit standard voltage/plugs in your region.</p>',
        isExpanded: false,
      },
      {
        title: 'Internet',
        data: `<div class="content"><p> The Digibox has to be connected by cable to the internet. Please
            check beforehand that:</p>
        <ul>
          <li>You have a good quality cabled internet connection. Minimum
              connection speed should be 20 Megabit. </li>
          <li>You have an internet cable in an appropriate length.</li>
        </ul>
        <p>Additional information about network requirements:</p>
        <ul>
          <li>The Digibox must have unrestricted access outward of the ports 80, 8080, 443, 8086, 5938, 4505 and 4506 – this will most likely be the default setting.</li>
          <li>TeamViewer should not be blocked as part of the firewall setting for the internet connection, or in Windows Defender or Windows Firewall.</li>
        </ul>
        </div>`,
        isExpanded: false,
      },
      {
        title: 'Light settings',
        data: `
          <div class="content">
          <p>Adjust the ceiling spots above the Digibox: If the lights are reflected on the LEGO box, the camera will have difficulties recognizing the LEGO box. </p>
          <img src="/img/lego_lights.jpeg" alt="Lego Lights">
          </div>
        `,
        isExpanded: false,
      },
    ];

    return { items };
  },
};
</script>
