import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { storeLoaded } from '@/utils';
import NotFound from '../views/NotFound.vue';

import Home from '../views/Home.vue';
import Order from '../views/Order.vue';
import Login from '../views/Login.vue';
import LoginUnknown from '../views/LoginUnknown.vue';
// import ForgotPassword from '../views/ForgotPassword.vue';
// import NewPassword from '../views/NewPassword.vue';
import Updates from '../views/Updates.vue';
import Requirements from '../views/Requirements.vue';
import InstallationProcedure from '../views/InstallationProcedure.vue';
// import Support from '../views/Support.vue';
import FAQ from '../views/FAQ.vue';
import TermsOfUse from '../views/TermsOfUse.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { redirectIfAuth: true },
  },
  {
    path: '/login-unknown',
    name: 'LoginUnknown',
    component: LoginUnknown,
    meta: { redirectIfAuth: true },
  },
  // @todo: uncomment when Forgot Password (POST) API is ready
  // {
  //   path: '/forgot-password',
  //   name: 'ForgotPassword',
  //   component: ForgotPassword,
  //   meta: { redirectIfAuth: true },
  // },
  // {
  //   path: '/new-password',
  //   name: 'NewPassword',
  //   component: NewPassword,
  //   meta: { redirectIfAuth: true },
  // },
  {
    path: '/updates',
    name: 'Updates',
    component: Updates,
    meta: { requiresAuth: true },
  },
  {
    path: '/requirements',
    name: 'Requirements',
    component: Requirements,
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
  },
  {
    path: '/installation-procedure',
    name: 'InstallationProcedure',
    component: InstallationProcedure,
  },
  {
    path: '/installment-procedure',
    redirect: {
      name: 'InstallationProcedure',
    },
  },
  {
    path: '/swap-unit',
    redirect: {
      name: 'InstallationProcedure',
    },
  },
  // @todo: uncomment when Support (POST) API is ready
  // {
  //   path: '/support',
  //   name: 'Support',
  //   component: Support,
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ,
    meta: { requiresAuth: true },
  },
  {
    // will match everything
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to) => {
  // Ensure store is loaded
  await storeLoaded(store);

  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !store.getters.authUser) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  } else if (to.meta.redirectIfAuth && store.getters.authUser) {
    return {
      path: '/',
    };
  }
});

export default router;
