import { computed } from 'vue';

export default function useValidationIcon(validation) {
  const stateIcon = computed(() => {
    if (!validation.value) {
      return '';
    }

    return validation.value.$dirty && !validation.value.$error
      ? 'fa-check-circle'
      : validation.value.$error
      ? 'fa-exclamation-circle'
      : '';
  });
  const stateIconColor = computed(() => {
    if (!validation.value) {
      return '';
    }
    return validation.value.$dirty && !validation.value.$error
      ? '#96c311'
      : validation.value.$error
      ? '#f47262'
      : '';
  });

  return {
    stateIcon,
    stateIconColor,
  };
}
