import { LegoBoxStates } from '@/constants';

// @todo: future: use third-party ?
export function deepClone(data) {
  return JSON.parse(JSON.stringify(data));
}

export function filterArrayObjectsByProp(arr, prop = 'id') {
  return arr.filter((v, i, a) => a.findIndex((t) => t[prop] === v[prop]) === i);
}

export function sortByProp(arr, prop = 'id', direction = 'asc') {
  arr.sort(function (a, b) {
    var propA = a[prop].toUpperCase();
    var propB = b[prop].toUpperCase();
    if (propA < propB) {
      return -1;
    }
    if (propA > propB) {
      return 1;
    }

    return 0;
  });

  if (direction === 'desc') {
    arr.reverse();
  }

  return arr;
}

export function preparePayloadGeneric(sections) {
  let result = {};

  sections.forEach((item) => {
    if (item.formFields) {
      for (let key in item.formFields) {
        const {
          apiFieldName,
          excludeFromPayload,
          prependFrom,
        } = item.formFields[key];
        let value = item.formFields[key].value;

        if (excludeFromPayload) {
          continue;
        }

        if (prependFrom) {
          const prependFromValue = item.formFields[prependFrom].value;
          if (prependFromValue) {
            value = prependFromValue + value;
          }
        }

        if (!apiFieldName) {
          console.error('apiFieldName is not specified');
          result = null;
          return;
        }
        result[apiFieldName] = value;
      }
    }
  });
  return result;
}

export function capitalize(value) {
  if (!value) {
    return '';
  }
  const valueAsStr = value.toString();
  return valueAsStr.charAt(0).toUpperCase() + valueAsStr.slice(1);
}

/**
 * Get all files from directory by provided context
 * @param {function} context webpack context
 * @returns {object} object of imported files
 */
export function importAllFilesFromDirectory(context) {
  const keys = context.keys();
  const values = keys.map(context);
  return keys.reduce(
    (acc, _, index) => ({
      ...acc,
      ...values[index],
    }),
    []
  );
}

export const actionWrapper = async (config, context) => {
  try {
    beforeActionStarted(config.apiKey, context);
    const data = await config.onApiCall();
    return config.onSuccess(data);
  } catch (e) {
    typeof config.onServerFail === 'function' ? config.onServerFail() : '';
    throw e || 'Something went wrong.';
  } finally {
    typeof config.onComplete === 'function' ? config.onComplete() : '';
    actionCompleted(config.apiKey, context);
  }
};

export function beforeActionStarted(actionKey, { commit }) {
  commit('setLoadingState', true);
  commit('addActiveApiAction', actionKey);
}

export function actionCompleted(actionKey, { commit }) {
  commit('removeActiveApiAction', actionKey);
  commit('setLoadingState', false);
}

export function scrollToTop() {
  document.getElementById('app').scrollIntoView({
    behavior: 'smooth',
  });
}

export function getBoxStatusClassIndicator(statusCode) {
  let result = '';

  switch (statusCode) {
    case LegoBoxStates.ONLINE_UP_TO_DATE:
      result = 'online';
      break;
    case LegoBoxStates.ONLINE_NEEDS_UPDATE:
      result = 'online online--needs-update';
      break;
    case LegoBoxStates.OFFLINE_UP_TO_DATE:
      result = 'offline';
      break;
    case LegoBoxStates.OFFLINE_NEEDS_UPDATE:
      result = 'offline offline--needs-update';
      break;
  }

  return result;
}

export function getBoxStatusLabel(statusCode) {
  let result = '';

  switch (statusCode) {
    case LegoBoxStates.ONLINE_UP_TO_DATE:
      result = `<span>Online + up-to-date</span>`;
      break;
    case LegoBoxStates.ONLINE_NEEDS_UPDATE:
      result = `<span>Online <i>+ Needs update</i></span>`;
      break;
    case LegoBoxStates.OFFLINE_UP_TO_DATE:
      result = `<span>Offline <i>+ up-to-date</i></span>`;
      break;
    case LegoBoxStates.OFFLINE_NEEDS_UPDATE:
      result = `<span>Offline <i>+ Needs update</i></span>`;
      break;
  }

  return result;
}

const isWithinOneDay = (date) => {
  const oneDay = 24 * 1000 * 60 * 60;
  const oneDayAgo = Date.now() - oneDay;
  return date > oneDayAgo;
};

export function determineBoxStatusCode(data) {
  let result = 0;
  const clientLastCheck = new Date(data.client.last_check).getTime();

  if (data.newestVersion === data.client.semver) {
    if (isWithinOneDay(clientLastCheck)) {
      result = LegoBoxStates.ONLINE_UP_TO_DATE;
    } else {
      result = LegoBoxStates.OFFLINE_UP_TO_DATE;
    }
  } else {
    if (isWithinOneDay(clientLastCheck)) {
      result = LegoBoxStates.ONLINE_NEEDS_UPDATE;
    } else {
      result = LegoBoxStates.OFFLINE_NEEDS_UPDATE;
    }
  }

  return result;
}

export function determineBoxDescription(statusCode) {
  let result = '';

  switch (statusCode) {
    case LegoBoxStates.ONLINE_UP_TO_DATE:
      result =
        'This Digibox is online and has been updated with the latest version of content.';
      break;
    case LegoBoxStates.ONLINE_NEEDS_UPDATE:
      result = 'This Digibox is online but needs update.';
      break;
    case LegoBoxStates.OFFLINE_UP_TO_DATE:
      result = 'This Digibox cannot be updated because it is offline.';
      break;
    case LegoBoxStates.OFFLINE_NEEDS_UPDATE:
      result =
        'This Digibox is offline and has not been updated to the latest version.';
      break;
  }

  return result;
}

export function setCookie(name, value, expiresInSeconds) {
  let expires = `expires=${expiresInSeconds}`;
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function removeCookie(name, path, domain) {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

export async function storeLoaded(store) {
  await new Promise((resolve) => {
    let counter = 0;
    setInterval(() => {
      counter++;
      if (!store.getters.isLoading || counter > 20) {
        resolve();
      }
    }, 200);
  });
}
