<template>
  <div class="static-page content">
    <h1 class="title">Terms of use</h1>
    <p>
      Only display the LEGO® DigiBox application on the LEGO® DigiBox unit
      installed.
    </p>
    <p>
      It is prohibited to display any other content on the LEGO® DigiBox unit
      other than the LEGO® DigiBox application. Please, do not close the app. If
      there are problems with using the application, please use the FAQ section,
      for answers to your questions or contact support, using the “Need help?“
      button.
    </p>
    <p>
      The LEGO® DigiBox will be decommissioned from stores that aren’t complaint
      with the terms of use.
    </p>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

export default {
  name: 'TermsOfUse',
  setup() {
    useMeta({
      title: 'Terms of use',
    });
  },
};
</script>
