<template>
  <div class="lego-before-steps">
    <div class="order-form-fields mb-6" role="group">
      <h3 class="subtitle">Before you begin:</h3>
      <p class="is-small-text mb-6">
        Before you order a Digibox, please make sure that you have read the
        requirements, and that requirements will be met before the desired
        delivery date.
      </p>
      <LegoCheckbox
        v-model:values="state.readRequirements"
        :id="formFields.readRequirements.id"
        :required="formFields.readRequirements.required"
        :value="formFields.readRequirements.value"
      >
        <template #label>
          I have read and understand
          <router-link to="/requirements">the requirements</router-link>.
        </template>
      </LegoCheckbox>
    </div>

    <div class="button-wrapper">
      <button
        :disabled="v$.$invalid"
        class="button is-primary is-rounded"
        @click="onConfirm"
      >
        Confirm
      </button>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import { useStore } from 'vuex';

import { BeforeOrderFormFields } from '@/constants/form-config';
import useValidation from '@/composables/useValidation';
import LegoCheckbox from '@/components/controls/LegoCheckbox';

export default {
  components: { LegoCheckbox },
  setup() {
    const store = useStore();
    const formFields = { ...BeforeOrderFormFields };

    let state = reactive({
      readRequirements: [],
    });

    const { v$ } = useValidation(formFields, state);

    const onConfirm = () => {
      if (!v$.$invalid) {
        store.dispatch('setOrderTermsAndConditions', true);
      }
    };

    return {
      v$,
      formFields,
      state,
      onConfirm,
    };
  },
};
</script>
