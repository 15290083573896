export const ApiFields = {
  // Store
  STEP_STORE_STORE_NAME: 'store_name',
  STEP_STORE_STORE_ADDRESS: 'store_address',
  STEP_STORE_ZIP_CODE_AND_CITY: 'store_zip_code_and_city',
  STEP_STORE_COUNTRY: 'store_country',

  STEP_STORE_STORE_MANAGER_NAME: 'store_manager_name',
  STEP_STORE_STORE_MANAGER_PHONE_NUMBER: 'store_manager_phone_number',
  STEP_STORE_STORE_MANAGER_EMAIL: 'store_manager_email',

  // Delivery
  STEP_DELIVERY_REQUESTED_DATE: 'delivery_requested_date',
  STEP_DELIVERY_CONTACT_PERSON_NAME: 'delivery_contact_person_name',
  STEP_DELIVERY_CONTACT_PERSON_PHONE_NUMBER: 'delivery_contact_person_phone',
  STEP_DELIVERY_CONTACT_PERSON_EMAIL: 'delivery_contact_person_email',
  STEP_DELIVERY_NOTES: 'delivery_notes',

  STEP_DELIVERY_CHOICE_OF_DELIVERY: 'delivery_choice_of_delivery',
  STEP_DELIVERY_DIFFERENT_COMPANY_NAME: 'delivery_different_company_name',
  STEP_DELIVERY_DIFFERENT_ADDRESS: 'delivery_different_address',
  STEP_DELIVERY_DIFFERENT_ZIP_CODE_CITY: 'delivery_different_zip_code_city',
  STEP_DELIVERY_DIFFERENT_COUNTRY: 'delivery_different_country',
  STEP_DELIVERY_DIFFERENT_CONTACT_PERSON: 'delivery_different_contact_person',
  STEP_DELIVERY_DIFFERENT_PHONE: 'delivery_different_phone',
  STEP_DELIVERY_DIFFERENT_EMAIL: 'delivery_different_email',

  // Invoice
  STEP_INVOICE_RESPONSIBLE_NAME: 'invoice_responsible_name',
  STEP_INVOICE_RESPONSIBLE_PHONE_NUMBER: 'invoice_responsible_phone_number',
  STEP_INVOICE_RESPONSIBLE_EMAIL: 'invoice_responsible_email',

  STEP_INVOICE_COMPANY_NAME: 'invoice_company_name',
  STEP_INVOICE_VAT_NUMBER: 'invoice_vat_number',
  STEP_INVOICE_INVOICING_ADDRESS: 'invoice_invoicing_address',
  STEP_INVOICE_ZIP_CODE_AND_CITY: 'invoice_zip_code_and_city',
  STEP_INVOICE_COUNTRY: 'invoice_country',
  STEP_INVOICE_EMAIL_FOR_INVOICES: 'invoice_email_for_invoices',
};
