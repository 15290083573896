import { deepClone } from '@/utils';
import { OrderSections, SupportSections } from '@/constants';

export default {
  authUser: null,
  acceptedTermsAndConditions: false,

  isLoading: false,
  activeApiActions: [],

  // Order Form
  currentOrderStep: 1,
  orderSections: deepClone(OrderSections),
  orderNumber: null,

  digiboxes: [],

  // Support Form
  currentSupportStep: 1,
  supportSections: deepClone(SupportSections),
};
