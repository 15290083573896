import axios from 'axios';
import { getCookie } from '@/utils';

const tokenId = getCookie('Token');
const headers = {};

if (tokenId) {
  headers['Authorization'] = tokenId;
}

let baseURL = '';
if (process.env.NODE_ENV === 'production') {
  baseURL = `${window.location.protocol}//video.${window.location.host}`;
  headers['Content-Type'] = 'application/x-www-form-urlencoded';
}

const instance = axios.create({
  baseURL,
  headers,
});

export const Http = instance;
