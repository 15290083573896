import { ApiSupportFields } from '@/constants';

export const SupportOpenTicketFormFields = {
  storeName: {
    name: 'field--support-store-name',
    label: 'Store name',
    maxlength: 255,
    required: false,
    rules: [],
    apiFieldName: ApiSupportFields.STEP_OPEN_STORE_NAME,
    value: '',
  },
  contactInformation: {
    name: 'field--support-contact-information',
    label: 'Contact information',
    placeholder: 'Email',
    maxlength: 255,
    required: true,
    rules: ['required', 'email'],
    apiFieldName: ApiSupportFields.STEP_OPEN_CONTACT_INFORMATION,
    value: '',
  },
  description: {
    name: 'field--support-description',
    label: 'Describe the problem',
    placeholder: 'What is the problem? How is the problem detected?',
    maxlength: 4000,
    required: true,
    rules: ['required'],
    apiFieldName: ApiSupportFields.STEP_OPEN_DESCRIPTION,
    value: '',
  },
  attempts: {
    name: 'field--support-attempts',
    label: 'What have you tried to fix it?',
    placeholder: "Power on? Working internet connection? See 'Requirements'",
    maxlength: 4000,
    required: true,
    rules: ['required'],
    apiFieldName: ApiSupportFields.STEP_OPEN_ATTEMPTS,
    value: '',
  },
  newSupportImage: {
    name: 'field--support-new-support-image',
    label: 'Add image file to ticket',
    required: false,
    rules: [],
    apiFieldName: ApiSupportFields.STEP_OPEN_NEW_IMAGE,
    value: '',
  },
};
