import {
  SupportWelcomeFormFields,
  SupportOpenTicketFormFields,
} from './form-config';

export const SupportSections = [
  {
    name: 'LegoSupportWelcomeStep',
    formFields: { ...SupportWelcomeFormFields },
  },
  {
    name: 'LegoSupportOpenTicketStep',
    formFields: { ...SupportOpenTicketFormFields },
  },
  {
    name: 'LegoSupportFinalStep',
  },
];
