import {
  ChoiceOfDeliveryOptions,
  ChoiceOfDeliveryOptionsAsObject,
} from '../options/ChoiceOfDeliveryOptions';

import { ApiFields } from '@/constants';

export const DeliveryFormFields = {
  requestedDeliveryDate: {
    name: 'field--delivery-requested-delivery-date',
    placeholder: 'Date, month and year',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_DELIVERY_REQUESTED_DATE,
    rules: ['required'],
    value: '',
  },
  contactPersonName: {
    name: 'field--delivery-contact-person-name',
    placeholder: 'Name',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_DELIVERY_CONTACT_PERSON_NAME,
    rules: ['required'],
    value: '',
  },
  contactPersonPhoneCountryCode: {
    name: 'field--delivery-contact-person-phone-country',
    placeholder: 'Country',
    maxlength: 3,
    required: true,
    rules: ['required'],
    value: '',
    excludeFromPayload: true,
  },
  contactPersonPhoneNumber: {
    name: 'field--delivery-contact-person-phone-number',
    placeholder: 'Phone number',
    maxlength: 15,
    required: true,
    apiFieldName: ApiFields.STEP_DELIVERY_CONTACT_PERSON_PHONE_NUMBER,
    rules: ['required'],
    prependFrom: 'contactPersonPhoneCountryCode',
    value: '',
  },
  contactPersonEmail: {
    name: 'field--delivery-contact-person-email',
    placeholder: 'E-mail',
    maxlength: 255,
    required: true,
    apiFieldName: ApiFields.STEP_DELIVERY_CONTACT_PERSON_EMAIL,
    rules: ['required', 'email'],
    value: '',
  },
  deliveryNotes: {
    name: 'field--delivery-notes',
    placeholder: 'Like a code, or a phone number?',
    label: 'Notes for delivery service:',
    maxlength: 4000,
    required: false,
    apiFieldName: ApiFields.STEP_DELIVERY_NOTES,
    rules: [],
    value: '',
  },
  choiceOfDelivery: {
    name: 'field--delivery-choice-of-delivery',
    required: true,
    apiFieldName: ApiFields.STEP_DELIVERY_CHOICE_OF_DELIVERY,
    options: [...ChoiceOfDeliveryOptions],
    rules: ['required'],
    value: ChoiceOfDeliveryOptionsAsObject.sameAsStore.value,
  },
  // Dynamic fields (based on choiceOfDelivery)
  deliveryDifferentCompanyName: {
    name: 'field--delivery-delivery-different-company-name',
    placeholder: 'Company name',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_COMPANY_NAME,
    required: true,
    rules: [],
    value: '',
  },
  deliveryDifferentAddress: {
    name: 'field--delivery-delivery-different-address',
    placeholder: 'Address',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_ADDRESS,
    required: true,
    rules: [],
    value: '',
  },
  deliveryDifferentZipCodeCity: {
    name: 'field--delivery-delivery-different-zip-code-city',
    placeholder: 'Zip code & City',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_ZIP_CODE_CITY,
    required: true,
    rules: [],
    value: '',
  },
  deliveryDifferentCountry: {
    name: 'field--delivery-delivery-different-country',
    placeholder: 'Country',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_COUNTRY,
    required: true,
    rules: [],
    value: '',
  },
  deliveryDifferentContactPerson: {
    name: 'field--delivery-delivery-different-contact-person',
    placeholder: 'Contact person',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_CONTACT_PERSON,
    required: true,
    rules: [],
    value: '',
  },
  deliveryDifferentPhoneCountryCode: {
    name: 'field--delivery-delivery-different-phone-country',
    placeholder: 'Country',
    maxlength: 3,
    required: true,
    rules: [],
    value: '',
    excludeFromPayload: true,
  },
  deliveryDifferentPhone: {
    name: 'field--delivery-delivery-different-phone',
    placeholder: 'Phone',
    maxlength: 15,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_PHONE,
    required: true,
    prependFrom: 'deliveryDifferentPhoneCountryCode',
    rules: [],
    value: '',
  },
  deliveryDifferentEmail: {
    name: 'field--delivery-delivery-different-email',
    placeholder: 'E-mail',
    maxlength: 255,
    apiFieldName: ApiFields.STEP_DELIVERY_DIFFERENT_EMAIL,
    required: true,
    rules: ['email'],
    value: '',
  },
};
