<template>
  <div class="static-page updates-page">
    <div class="lego-search columns mb-5">
      <div class="column is-half">
        <LegoSearchSku @search="filteredText = $event" />
      </div>
    </div>
    <LegoDigitalBoxes v-if="!filteredText" :boxes="digiboxes" class="mb-6" />
    <div v-if="!filteredItems.length">
      <h4 class="subtitle is-size-3">
        Sorry! There's no result for '{{ filteredText }}' at the moment.
      </h4>
    </div>
    <LegoCollapsibleItem
      v-for="(
        { id, title, date, data, isExpanded, isCurrent }, index
      ) in filteredItems"
      :key="id"
      :expanded="isExpanded"
      class="mb-5"
    >
      <template #sectionHeader>
        <h3 v-if="isCurrent" class="title mt-6">Current version:</h3>
        <h3
          v-else-if="showPreviousUpdates(filteredItems, index)"
          class="title mt-6"
        >
          <LegoIcon icon="fa-history" class="mx-2" />
          Previous updates:
        </h3>
      </template>
      <template #header>
        <p>{{ title }}</p>
      </template>
      <template #content>
        <p class="has-text-weight-semibold is-small-text is-danger-text mb-4">
          {{ date }}
        </p>
        <ul class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
          <li v-for="(dataItem, key) in data" :key="key">
            <span :class="{ 'is-highlighted': dataItem.active }">{{
              dataItem.value
            }}</span>
            <span>&nbsp;</span>
          </li>
        </ul>
      </template>
    </LegoCollapsibleItem>
    <template v-if="!filteredText">
      <LegoCollapsibleItem :expanded="false" class="mb-5">
        <template #header>
          <p>The content of the latest Digibox version</p>
        </template>
        <template #content>
          <p class="has-text-weight-semibold is-small-text is-danger-text mb-4">
            2018/04/06 - 12:04
          </p>
          <p>
            As requested. Included is a list of all the content that is included
            in the latest 2018.1.0.0 update.
          </p>
        </template>
      </LegoCollapsibleItem>
      <LegoCollapsibleItem :expanded="false" class="mb-5">
        <template #header>
          <p>The latest Digibox version update</p>
        </template>
        <template #content>
          <p class="has-text-weight-semibold is-small-text is-danger-text mb-4">
            2018/01/17 - 11:01
          </p>
          <p>
            The latest Digibox version update containing the new LEGO City
            content is expected to be online around January 18, 2018.
          </p>
        </template>
      </LegoCollapsibleItem>
    </template>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';
import { deepClone, filterArrayObjectsByProp, sortByProp } from '@/utils';
import { LegoBoxUpdates } from '@/constants';

import LegoCollapsibleItem from '@/components/base/LegoCollapsibleItem';
import LegoDigitalBoxes from '@/components/base/LegoDigitalBoxes';
import LegoIcon from '@/components/base/LegoIcon';
import LegoSearchSku from '@/components/base/LegoSearchSku';

export default {
  name: 'Updates',
  components: {
    LegoSearchSku,
    LegoIcon,
    LegoDigitalBoxes,
    LegoCollapsibleItem,
  },
  setup() {
    useMeta({
      title: 'Updates',
    });
    const store = useStore();
    const filteredText = ref('');

    const digiboxes = computed(() => store.getters.digiboxes);
    const items = LegoBoxUpdates.map((item) => {
      let data = item.data;
      if (typeof data === 'string' || data instanceof String) {
        data = data.split('\n');
      }
      data = data.map((sku) => {
        return { value: sku, active: false };
      });
      return {
        ...item,
        // UI Adapter
        data,
      };
    });

    const filteredItems = computed(() => {
      const searchArray = filteredText.value.split(' ');
      let result = [];

      if (!filteredText.value) {
        return items;
      }

      const clonedItems = deepClone(items);

      for (let searchItem of searchArray) {
        result = [...result, ...searchByOneCriteria(searchItem, clonedItems)];
      }
      return sortByProp(filterArrayObjectsByProp(result), 'id', 'desc');
    });

    const searchByOneCriteria = (searchItem, data) => {
      return data.filter((item) => {
        let isFound = false;

        item.data.forEach((itemData) => {
          if (itemData.value === +searchItem) {
            isFound = true;
            item.isExpanded = true;
            itemData.active = true;
          }
        });
        return isFound ? item : null;
      });
    };

    const showPreviousUpdates = (data, index) => {
      let result = false;

      if (index === 0 && !data[index].isCurrent) {
        result = true;
      } else if (index === 1 && data[0].isCurrent) {
        result = true;
      }

      return result;
    };

    return { filteredText, filteredItems, digiboxes, showPreviousUpdates };
  },
};
</script>
