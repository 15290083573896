<template>
  <ul class="lego-order-steps">
    <li class="step-item" v-for="(item, index) in steps" :key="index">
      <button
        class="button is-static step-button"
        :class="{ active: showActive && isActiveStep(index) }"
        :disabled="disabled && !item.isFinal"
      >
        <LegoIcon :icon="item.icon" />
        <span class="step-title">{{ item.title }}</span>
      </button>
    </li>
  </ul>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import LegoIcon from '@/components/base/LegoIcon';

export default {
  name: 'LegoOrderSteps',
  components: { LegoIcon },
  props: {
    showActive: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const steps = ref([
      {
        icon: 'fa-address-card',
        title: 'Store',
      },
      {
        icon: 'fa-truck',
        title: 'Delivery',
      },
      {
        icon: 'fa-receipt',
        title: 'Invoice',
      },
      {
        icon: 'fa-check-circle',
        title: 'Receipt',
        isFinal: true,
      },
    ]);
    const currentOrderStep = computed(() => store.getters.currentOrderStep);

    const isActiveStep = (index) => {
      return currentOrderStep.value === index + 1;
    };

    return {
      steps,
      isActiveStep,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.lego-order-steps {
  display: flex;
}

.step-button {
  color: $lego-icon-color;
  border-radius: 10px;
  background: linear-gradient(
    $lego-gradient-steps-from,
    $lego-gradient-steps-to
  );
  box-shadow: 3px 3px 10px #e0e0e0, -3px -3px 10px #fff;
  border-color: transparent;
  display: block;
  font-size: 0.7rem;
  font-weight: 600;
  width: 4rem;
  height: 4rem;
  margin: 0 1rem 0 0;
  padding: 0.75rem;

  @media (min-width: $lego-breakpoints-tablet) {
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    font-size: $lego-icon-font-size;
  }

  .step-title {
    display: block;
  }

  ::v-deep(.icon-item) {
    font-size: 1rem;
    min-height: 1.5rem;

    @media (min-width: $lego-breakpoints-tablet) {
      font-size: 1.3rem;
      min-height: 2.2rem;
    }
  }

  &.active {
    color: $lego-icon-color--active;
    border-color: $lego-icon-color--active;
    box-shadow: inset 4px 4px 8px #bebebe, -4px -4px 8px $lego-body-bg-color;
  }
}
</style>
