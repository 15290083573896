import { deepClone, removeCookie } from '@/utils';
import { OrderSections, SupportSections } from '@/constants';

export default {
  setAuthUser: (state, payload) => {
    state.authUser = payload;
  },
  setOrderTermsAndConditions: (state, payload) => {
    state.acceptedTermsAndConditions = payload;
  },
  setLoadingState: (state, loadingState) => {
    state.isLoading = !!loadingState;
  },
  addActiveApiAction: (state, actionKey) => {
    state.activeApiActions.push(actionKey);
  },
  removeActiveApiAction: (state, actionKey) => {
    state.activeApiActions = state.activeApiActions.filter(
      (action) => action !== actionKey
    );
  },
  eraseLogin: (state) => {
    state.authUser = null;
    removeCookie('Token');
  },
  setOrderStep: (state, payload) => {
    state.currentOrderStep = payload;
  },
  setOrderNumber: (state, payload) => {
    state.orderNumber = payload;
  },
  saveFormField: (state, payload) => {
    const current = payload.currentStep - 1;
    state[payload.moduleSections][current].formFields[payload.key] = {
      ...state[payload.moduleSections][current].formFields[payload.key],
      [payload.valueKey]: payload.value,
    };
  },
  clearOrderForm: (state) => {
    state.currentOrderStep = 1;
    state.orderNumber = null;
    state.orderSections = deepClone(OrderSections);
  },
  setDigiboxes: (state, payload) => {
    state.digiboxes = payload;
  },

  // Support
  setSupportStep: (state, payload) => {
    state.currentSupportStep = payload;
  },
  clearSupportForm: (state) => {
    state.currentSupportStep = 1;
    state.supportSections = deepClone(SupportSections);
  },
};
