<template>
  <div class="field lego-datepicker">
    <div class="control">
      <date-picker
        :value="fieldValue"
        :type="type"
        :lang="lang"
        :placeholder="placeholder"
        :format="format"
        :editable="editable"
        :clearable="clearable"
        :disabled-date="disabledDate"
        valueType="format"
        @input="onInput"
      >
        <template #icon-calendar>
          <LegoIcon
            v-if="stateIcon && validation"
            :style="{
              color: stateIconColor,
            }"
            class="is-small"
            :icon="stateIcon"
          />
          <LegoIcon v-else class="is-small" icon="fa-calendar" />
        </template>
      </date-picker>
    </div>
  </div>
</template>

<script>
import LegoIcon from '@/components/base/LegoIcon';
import useValidationIcon from '@/composables/useValidationIcon';

import { computed, toRefs } from 'vue';

export default {
  name: 'LegoDatepickerInput',
  components: { LegoIcon },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    disabledDate: {
      type: Function,
      default: () => {},
    },
    lang: {
      type: Object,
      default: () => {
        return {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        };
      },
    },
    type: {
      type: String,
      default: 'date', //date|datetime|year|month|time|week
    },
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'DD-MMM-YYYY',
    },
    validation: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue', 'onInput', 'onBlur'],
  setup(props, { emit }) {
    const { modelValue, validation } = toRefs(props);
    const { stateIcon, stateIconColor } = useValidationIcon(validation);
    const fieldValue = modelValue;
    const id = computed(() => `${props.name}-id`);

    const onInput = (e) => {
      emit('update:modelValue', e);
      emit('input', e);
    };

    return {
      fieldValue,
      id,
      onInput,
      stateIcon,
      stateIconColor,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/helpers';

.lego-datepicker {
  .mx-datepicker {
    width: 100%;
  }
  .mx-input {
    background-color: $input-background-color;
    border-color: #dbdbdb;
    border-radius: $input-radius;
    box-shadow: $input-shadow;
    color: #363636;
    font-size: 1rem;
    height: 2.5em;
    padding-left: 1rem;
    padding-right: 2rem;

    &::-webkit-input-placeholder {
      color: #b8b9bc;
    }

    &:hover,
    &:focus {
      border-color: $input-focus-border-color;
      box-shadow: $input-shadow-focus;
    }
  }
  .mx-icon-calendar {
    right: 0.75rem;
  }
}
</style>
