<template>
  <div class="lego-info-box card">
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegoInfoBox',
};
</script>

<style lang="scss">
.lego-info-box {
  height: 100%;
}
</style>
