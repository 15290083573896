import useVuelidate from '@vuelidate/core';
import { unref } from 'vue';
import * as Validators from '@vuelidate/validators';

export default function useValidation(formFields, state) {
  const formFieldsUnRef = unref(formFields);
  const rules = {};

  const validationReducer = (data) =>
    data.reduce((acc, item) => {
      acc[item] = Validators[item];
      return acc;
    }, {});

  for (let key in formFieldsUnRef) {
    rules[key] = validationReducer(formFieldsUnRef[key].rules);
  }

  const v$ = useVuelidate(rules, state);

  return {
    v$,
  };
}
