<template>
  <div class="static-page login-page">
    <LegoHeader class="mb-6">{{ appTitle }}</LegoHeader>
    <div class="columns">
      <div class="column is-5">
        <h3 class="subtitle">Please log in:</h3>
        <LegoMessage v-if="state.apiErrorMessage" class="is-danger">
          <template #body>
            <p class="mb-2">{{ state.apiErrorMessage }}</p>
            <p>
              <router-link to="/login-unknown"
                >Register a new account</router-link
              >
            </p>
          </template>
        </LegoMessage>
        <form class="main-form-wrap mb-6" @submit.prevent>
          <LegoTextInput
            v-model.trim="state.login"
            autocomplete="username"
            :required="formFields.login.required"
            :maxlength="formFields.login.maxlength"
            :name="formFields.login.name"
            :placeholder="formFields.login.placeholder"
            :validation="v$.login"
            @input="v$.login.$touch"
          />
          <LegoTextInput
            v-model.trim="state.password"
            autocomplete="current-password"
            :required="formFields.password.required"
            :maxlength="formFields.password.maxlength"
            :name="formFields.password.name"
            :placeholder="formFields.password.placeholder"
            :validation="v$.password"
            @input="v$.password.$touch"
            input-type="password"
            class="mb-5"
          />
          <p class="has-text-centered mb-4">
            <button
              :disabled="isLoading"
              class="button is-primary is-rounded"
              @click="submitLogin"
            >
              Log in
            </button>
          </p>
          <!--          <p class="has-text-centered is-small-text">-->
          <!--            <router-link-->
          <!--              class="is-text-decoration-underline"-->
          <!--              to="/forgot-password"-->
          <!--            >-->
          <!--              I forgot my password-->
          <!--            </router-link>-->
          <!--          </p>-->
        </form>
        <LegoPrivacyPolicy />
      </div>
      <div class="column is-narrow">
        <LegoInstallationInstructions />
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useValidation from '@/composables/useValidation';

import LegoHeader from '@/components/base/LegoHeader';
import LegoInstallationInstructions from '@/components/base/LegoInstallationInstructions';
import LegoPrivacyPolicy from '@/components/base/LegoPrivacyPolicy';
import LegoTextInput from '@/components/controls/LegoTextInput';

import { LoginFormFields } from '@/constants/form-config';
import LegoMessage from '../components/base/LegoMessage';

export default {
  name: 'Login',
  components: {
    LegoMessage,
    LegoTextInput,
    LegoPrivacyPolicy,
    LegoInstallationInstructions,
    LegoHeader,
  },
  setup() {
    useMeta({
      title: 'Login',
    });

    const store = useStore();
    const router = useRouter();

    let state = reactive({
      login: '',
      password: '',
      apiErrorMessage: '',
    });

    let formFields = reactive({
      login: LoginFormFields.login,
      password: LoginFormFields.password,
    });

    const { v$ } = useValidation(formFields, state);

    const isLoading = computed(() => store.getters.isLoading);
    const doLogin = (payload) => store.dispatch('doLogin', payload);

    const submitLogin = async () => {
      state.apiErrorMessage = null;
      if (v$.value.$invalid) {
        return;
      }

      const payload = {
        username: state.login,
        password: state.password,
      };

      try {
        const { error } = await doLogin(payload);
        if (error) {
          handleError(error);
          return;
        }
        afterSuccessLogin();
      } catch (e) {
        console.error(e);
        handleError(e);
      }
    };

    const handleError = (error) => {
      state.apiErrorMessage = error.message || 'Some error occurred.';
    };

    const afterSuccessLogin = () => {
      const { redirect } = router.currentRoute.value.query;
      location.href = redirect ?? '/';
    };

    return {
      state,
      formFields,
      v$,
      isLoading,
      submitLogin,
      appTitle: process.env.VUE_APP_TITLE,
    };
  },
};
</script>
