<template>
  <div class="checkbox-input mb-5 is-flex is-align-items-center">
    <input
      v-bind="$attrs"
      :checked="checked"
      :required="required"
      :id="id"
      :name="name"
      type="checkbox"
      class="checkbox"
      @input="check()"
    />
    <label :for="id" class="checkbox is-small-text"
      ><slot name="label"
    /></label>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'LegoCheckbox',
  emits: ['update:values'],
  props: {
    value: {
      type: [String, Boolean],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.values.includes(props.value));

    function check() {
      let updatedValues = [...props.values];
      if (this.checked) {
        updatedValues.splice(updatedValues.indexOf(props.value), 1);
      } else {
        updatedValues.push(props.value);
      }
      context.emit('update:values', updatedValues);
    }

    return {
      check,
      checked,
    };
  },
};
</script>

<style lang="scss" scoped>
$radio-size: 1.5rem;

.checkbox-input {
  position: relative;

  .checkbox {
    padding-left: 0.75rem;
  }

  input[type='checkbox'] {
    width: $radio-size;
    height: $radio-size;
    min-height: $radio-size;
    min-width: $radio-size;
    vertical-align: middle;
  }
}
</style>
