export const LoginUnknownFormFields = {
  name: {
    name: 'field--login-unknown-name',
    placeholder: 'Your name',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
  email: {
    name: 'field--login-unknown-email',
    placeholder: 'Your email',
    maxlength: 255,
    required: true,
    rules: ['required', 'email'],
    value: '',
  },
  store: {
    name: 'field--login-unknown-store',
    placeholder: 'Your store',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
  cityAndCountry: {
    name: 'field--login-unknown-city-and-country',
    placeholder: 'City and country',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
  phone: {
    name: 'field--login-unknown-phone',
    placeholder: 'Phone number',
    maxlength: 255,
    required: true,
    rules: ['required'],
    value: '',
  },
};
