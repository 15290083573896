<template>
  <span
    class="icon"
    :class="[containerClass]"
    :title="title"
    :aria-hidden="hidden"
  >
    <i :class="[namespace, icon]" class="icon-item"></i>
  </span>
</template>

<script>
export default {
  name: 'LegoIcon',
  props: {
    namespace: {
      type: String,
      default: 'far',
    },
    icon: {
      type: String,
      required: true,
      note: 'fa-home',
    },
    hidden: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    containerClass: {
      type: [String, Object],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.light-icon {
  width: auto;

  .icon-item {
    color: $lego-icon-color-lighter;
    font-size: $lego-icon-font-size;
  }
}
</style>
