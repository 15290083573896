<template>
  <div class="lego-order-main">
    <LegoBackBtn :disabled="backButtonDisabled" @onBack="onBackButton" />
    <LegoOrderSteps
      :show-active="acceptedTermsAndConditions"
      :disabled="isFinalOrderStep || isLoading"
      class="mb-6"
    />

    <div class="columns">
      <div class="column is-5">
        <div v-if="!isLoading" class="step-form-wrap">
          <form class="main-form-wrap" @submit.prevent>
            <LegoBeforeSteps v-if="!acceptedTermsAndConditions" />
            <component v-else :is="currentStepComponent" @onNext="onNext" />
          </form>
        </div>
        <div class="has-text-centered pt-6" v-else>
          <img src="@/assets/images/bars.svg" width="140" alt="Loading.." />
        </div>
      </div>
      <div class="column is-narrow">
        <LegoNeedAssistance />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { scrollToTop } from '@/utils';

import LegoBeforeSteps from './steps/LegoBeforeSteps';

import LegoStepStore from './steps/LegoStepStore';
import LegoStepDelivery from './steps/LegoStepDelivery';
import LegoStepInvoice from './steps/LegoStepInvoice';
import LegoStepReceipt from './steps/LegoStepReceipt';

import LegoBackBtn from '@/components/base/LegoBackBtn';
import LegoOrderSteps from '@/components/order/LegoOrderSteps';
import LegoNeedAssistance from '@/components/order/LegoNeedAssistance';

export default {
  name: 'LegoOrderMain',
  components: {
    LegoBeforeSteps,
    LegoStepStore,
    LegoStepDelivery,
    LegoStepInvoice,
    LegoStepReceipt,

    LegoBackBtn,

    LegoOrderSteps,
    LegoNeedAssistance,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const isValidStep = ref(true);

    const isLoading = computed(() => store.getters.isLoading);
    const isFinalOrderStep = computed(() => store.getters.isFinalOrderStep);
    const orderSections = computed(() => store.getters.orderSections);
    const currentOrderStep = computed(() => store.getters.currentOrderStep);
    const backButtonDisabled = computed(() => {
      return isFinalOrderStep.value;
    });
    const currentStepComponent = computed(
      () => orderSections.value[currentOrderStep.value - 1].name
    );
    const allDataForOrderApi = computed(() => store.getters.allDataForOrderApi);
    const acceptedTermsAndConditions = computed(
      () => store.getters.acceptedTermsAndConditions
    );

    const saveFormField = (payload) => store.dispatch('saveFormField', payload);
    const goToOrderStep = (payload) => store.dispatch('goToOrderStep', payload);
    const saveOrder = (payload) => store.dispatch('saveOrder', payload);
    const setOrderNumber = (payload) =>
      store.dispatch('setOrderNumber', payload);

    const saveFormFieldsToStore = (payload, valueKey) => {
      for (let item in payload) {
        saveFormField({
          key: item,
          valueKey: valueKey,
          value: payload[item],
          currentStep: currentOrderStep.value,
          moduleSections: 'orderSections',
        });
      }
    };

    const stepIsValid = (data) => {
      return !data.v$.$invalid;
    };

    const onNext = async (data) => {
      const nextStep = currentOrderStep.value + 1;
      isValidStep.value = stepIsValid(data);

      if (!isValidStep.value) {
        return;
      }

      if (data.action) {
        // Save to store
        saveFormFieldsToStore(data.payload, 'value');

        if (data.action === 'save') {
          const payload = allDataForOrderApi.value;

          if (!payload) {
            return;
          }

          try {
            const data = await saveOrder(payload);
            if (data.id) {
              await setOrderNumber(data.id);
              await goToOrderStep(nextStep);
              scrollToTop();
            } else {
              console.error('Order id is missing.');
            }
          } catch (response) {
            console.error(response.data);
          }
        } else {
          await goToOrderStep(nextStep);
          scrollToTop();
        }
      }
    };

    const onBackButton = () => {
      if (currentOrderStep.value <= 1) {
        router.push('/');
        return;
      }
      const payload = currentOrderStep.value - 1;
      goToOrderStep(payload);
    };

    return {
      isLoading,
      isValidStep,
      currentStepComponent,
      acceptedTermsAndConditions,
      currentOrderStep,
      backButtonDisabled,
      isFinalOrderStep,
      onBackButton,
      onNext,
    };
  },
};
</script>
