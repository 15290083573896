<template>
  <div class="static-page faq-page">
    <h1 class="title">FAQ</h1>
    <p>
      If none of the below suggestions work, please use the
      <b>"Need help?"</b> button in the sidebar or contact
      <a href="mailto:lego@proreact.dk">lego@proreact.dk</a> for help. Please
      remember to add a video or a picture of the issue.
    </p>
    <br />
    <LegoCollapsibleItem class="mb-5">
      <template #header> Digibox doesn't recognize LEGO boxes </template>
      <template #content>
        <div class="content">
          <p>Please check:</p>
          <ul>
            <li>
              If the Digibox is offline, it might not have received the latest
              software version necessary to run. Please check your internet
              connection. The recommended connection speed is 20 Megabit or
              more.
            </li>
            <li>
              That the LEGO box is supported by the Digibox. You can find
              information in our
              <router-link to="/updates">latest update</router-link>.
            </li>
            <li>
              If the ceiling spots above the Digibox are placed so that the
              light is reflected on the LEGO box, the camera will have
              difficulties recognizing the LEGO box.
            </li>
          </ul>
          <p>
            <img src="/img/lego_lights.jpeg" alt="Lego Lights" />
          </p>
          <ul>
            <li>
              If this does not solve the problem, try to adjust the camera.
            </li>
          </ul>
          <p>
            <video width="480" height="270" preload="" controls="">
              <source
                src="https://lego.proreact.dk/video/camera_1.mp4"
                type="video/mp4"
              />
            </video>
          </p>
          <p>
            <i>*Note: Video has no audio</i>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> There is no camera feed </template>
      <template #content>
        <div class="content">
          <p>
            Please check that the camera is correctly connected to the computer.
          </p>
          <p>
            <video width="480" height="270" preload="" controls="">
              <source
                src="https://lego.proreact.dk/video/nocamerafeed_1.mp4"
                type="video/mp4"
              />
            </video>
          </p>
          <p>
            <i>*Note: Video has no audio</i>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header>Digibox is showing a black screen</template>
      <template #content>
        <div class="content">
          <p>Please check:</p>
          <ul>
            <li>That the Digibox is powered up and has internet connection.</li>
            <li>
              That the screen/monitor is turned on – if not, this is easily done
              with the remote control.
            </li>
          </ul>
          <p>
            <video width="480" height="270" preload="" controls="">
              <source
                src="https://lego.proreact.dk/video/blackscreen_1.mp4"
                type="video/mp4"
              />
            </video>
          </p>
          <p>
            Please create a
            <router-link to="/support">support ticket</router-link> if this does
            not solve the problem.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header>
        Digibox is showing a blue screen with error messages</template
      >
      <template #content>
        <div class="content">
          <p>
            <img src="/img/bluescreen.jpeg" alt="Blue screen of death" />
          </p>
          <p>Please try:</p>
          <ul>
            <li>
              Turn off the power to the Digibox (by unplugging the power plug).
              Turn the power back on.
            </li>
          </ul>
          <p>
            <img
              style="width: 600px"
              src="/img/bluescreen2.png"
              alt="Blue screen of death"
            />
          </p>
          <p>
            If you get an blue screen with “Automatic Repair” displayed, this
            means that the Digibox computer has been shut down by the power
            being turned off instead of the proper shutdown procedure. After
            several of these heavy shutdowns the computer will be damaged.
            Please reach out to the Digibox support team at
            <a href="mailto:lego@proreact.dk">lego@proreact.dk</a>. They can
            bill and ship a new computer for you.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> No signal </template>
      <template #content>
        <div class="content">
          <p>If the screen shows "No signal":</p>
          <ul>
            <li>Check that the computer is turned on.</li>
            <li>
              Check that all cables are sufficiently connected in both ends,
              especially the HDMI cable connecting the screen and the computer.
            </li>
          </ul>
          <p>
            Using the remote control or the joystick on the back of the screen,
            please switch to the HDMI source.
          </p>
          <p>
            <video width="480" height="270" preload="" controls="">
              <source
                src="https://lego.proreact.dk/video/nosignal_1.mp4"
                type="video/mp4"
              />
            </video>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> Camera adjustment </template>
      <template #content>
        <div class="content">
          <p>
            If the camera direction is not properly adjusted, it will have
            difficulties recognizing the LEGO boxes.
          </p>
          <p>
            <video width="480" height="270" preload="" controls="">
              <source
                src="https://lego.proreact.dk/video/camera_1.mp4"
                type="video/mp4"
              />
              /video&gt;
            </video>
            <br />
            <i>*Note: Video has no audio</i>
          </p>
          <p><b>Please adjust the camera as shown in the video.</b></p>
          <p>
            It is important, upon installation of the Digibox, that the camera
            is not placed too close to LEGO boxes on the nearby shelves.
            Ideally, you should place the LEGO boxes 3-4 metres away from the
            webcamera. If they are placed too close, the camera will pick up on
            the boxes on the shelves in front of it and look occupied, which
            means that fewer customers will use it. Also, the call to action
            video in the center, which helps to draw attention to the Digibox,
            will disappear.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> Do I have the latest update? </template>
      <template #content>
        <div class="content">
          <p>
            The Digibox automatically receives updates. You can find information
            about the latest update in the
            <router-link to="/updates">updates section</router-link>.
          </p>

          <p>
            To get updates, the computer must be connected by a cable to the
            internet and have a minimum of 20 Mbit connection. If you have any
            doubts regarding the updates of your Digibox, please contact Digibox
            support team at
            <a href="mailto:lego@proreact.dk">lego@proreact.dk</a>.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> When is startup/shutdown time? </template>
      <template #content>
        <div class="content">
          <p>
            It is recommend that the Digibox is on a 24/7 power supply, always.
            However if this is not possible, the Digibox support team can define
            presettings for your Digibox. Please contact
            <a href="mailto:lego@proreact.dk">lego@proreact.dk</a> for a
            preinstalled time schedule – the team will need to know the store's
            opening hours to make the settings.
          </p>

          <p>
            Please also check your internet connection. If the computer is not
            connected to the internet, it cannot adjust to your time zone.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem class="mb-5">
      <template #header> Vuforia error </template>
      <template #content>
        <div class="content">
          <p>
            If this error shows up on the screen, it is because the Logitech
            webcam is either failing or the Digibox cannot recognize the camera.
          </p>
          <p>
            <img src="/img/vuforia-error.png" alt="Blue screen of death" />
          </p>
          <p>
            Please remove the USB cable for the webcam and plug it in again. If
            the problem continues, please try a different USB port.
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import LegoCollapsibleItem from '@/components/base/LegoCollapsibleItem';

export default {
  name: 'FAQ',
  components: { LegoCollapsibleItem },
  setup() {
    useMeta({
      title: 'FAQ',
    });
  },
};
</script>
