import { computed, unref } from 'vue';
import { useStore } from 'vuex';

export default function useStep(moduleFormFields = 'orderFormFields') {
  const store = useStore();
  const isLoading = computed(() => store.getters.isLoading);
  const formFields = computed(() => store.getters[moduleFormFields]);
  const unrefFormFields = unref(formFields);

  return {
    isLoading,
    formFields,
    unrefFormFields,
  };
}
