import * as legoApi from '@/services/lego-api';
import { actionWrapper, setCookie } from '@/utils';

export default {
  setLoadingState({ commit }, data) {
    commit('setLoadingState', data);
  },
  setOrderTermsAndConditions({ commit }, data) {
    commit('setOrderTermsAndConditions', data);
  },
  goToOrderStep({ commit }, data) {
    return commit('setOrderStep', data);
  },
  saveFormField({ commit }, data) {
    return commit('saveFormField', data);
  },
  resetOrderForm: ({ commit }) => {
    return commit('clearOrderForm');
  },
  setOrderNumber: ({ commit }, payload) => {
    return commit('setOrderNumber', payload);
  },
  goToSupportStep({ commit }, data) {
    return commit('setSupportStep', data);
  },
  resetSupportForm: ({ commit }) => {
    return commit('clearSupportForm');
  },
  saveOrder: async (context, payload) => {
    return actionWrapper(
      {
        apiKey: 'saveOrder',
        onApiCall: async () => {
          const { data } = await legoApi.saveOrder(payload);
          return data;
        },
        onSuccess: (data) => {
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },

  async checkLogin(context) {
    const { commit } = context;

    return actionWrapper(
      {
        apiKey: 'checkLogin',
        onApiCall: async () => {
          const { data } = await legoApi.checkLogin();
          return data;
        },
        onSuccess: (data) => {
          if (data.email) {
            commit('setAuthUser', data);
          }
          if (data.client) {
            commit('setDigiboxes', [data.client]);
          }
          return data;
        },
        onServerFail: () => {
          commit('eraseLogin');
          location.href = '/';
        },
      },
      context
    );
  },

  async doLogin(context, payload) {
    const { commit } = context;
    return actionWrapper(
      {
        apiKey: 'doLogin',
        onApiCall: async () => {
          const { data } = await legoApi.doLogin(payload);
          return data;
        },
        onSuccess: (data) => {
          if (data.token) {
            commit('setAuthUser', data);
            const d = new Date();
            d.setTime(d.getTime() + 60 * 60 * 1000); // 1 hour
            setCookie('Token', data.token, d.toUTCString());
          }
          return data;
        },
        onServerFail: (error) => {
          return error;
        },
      },
      context
    );
  },

  async doLogout(context) {
    const { commit } = context;
    return actionWrapper(
      {
        apiKey: 'doLogout',
        onApiCall: async () => {
          const { data } = await legoApi.doLogout();
          return data;
        },
        onSuccess: (data) => {
          commit('eraseLogin');
          location.href = '/';
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },

  async doForgotPassword(context, payload) {
    return actionWrapper(
      {
        apiKey: 'doForgotPassword',
        onApiCall: async () => {
          const { data } = await legoApi.doForgotPassword(payload);
          return data;
        },
        onSuccess: (data) => {
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },

  async createNewPassword(context, payload) {
    return actionWrapper(
      {
        apiKey: 'createNewPassword',
        onApiCall: async () => {
          const { data } = await legoApi.createNewPassword(payload);
          return data;
        },
        onSuccess: (data) => {
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },

  async doLoginUnknown(context, payload) {
    return actionWrapper(
      {
        apiKey: 'doLoginUnknown',
        onApiCall: async () => {
          const { data } = await legoApi.doLoginUnknown(payload);
          return data;
        },
        onSuccess: (data) => {
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },

  sendTicket: async (context, payload) => {
    return actionWrapper(
      {
        apiKey: 'sendTicket',
        onApiCall: async () => {
          const { data } = await legoApi.sendTicket(payload);
          return data;
        },
        onSuccess: (data) => {
          return data;
        },
        onServerFail: (error) => {
          throw error;
        },
      },
      context
    );
  },
};
