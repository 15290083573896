import { Http } from '@/plugins/axios';

let mockAdapter = {};
try {
  if (process.env.VUE_APP_API_MOCKS_ENABLED === 'true') {
    mockAdapter = require(`./mocks/lego-api-mocks`);
    mockAdapter.mockApi(Http);
  }
} catch (e) {
  console.log(e);
}

export function checkLogin() {
  return Http.request({
    url: '/api/login/me',
    method: 'get',
  });
}

export function doLogin(data) {
  return Http.request({
    url: '/api/login',
    method: 'post',
    data,
  });
}

export function doLogout() {
  return Http.request({
    url: '/api/logout',
    method: 'post',
  });
}

export function doForgotPassword(data) {
  return Http.request({
    url: '/api/forgot-password',
    method: 'post',
    data,
  });
}

export function createNewPassword(data) {
  return Http.request({
    url: '/api/new-password',
    method: 'post',
    data,
  });
}

export function doLoginUnknown(data) {
  return Http.request({
    url: '/api/login/unknown',
    method: 'post',
    data,
  });
}

export function saveOrder(data) {
  return Http.request({
    url: '/api/purchase',
    method: 'post',
    data,
  });
}

export function sendTicket(data) {
  return Http.request({
    url: '/api/support',
    method: 'post',
    data,
  });
}
