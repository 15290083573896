<template>
  <div class="radio-input">
    <input
      :id="id"
      :name="name"
      :value="value"
      :required="required"
      :checked="checked"
      type="radio"
      @change="onInput"
    />
    <label class="radio" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'LegoRadioInput',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
      note: 'Associated label',
    },
    required: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const onInput = (e) => {
      const value = e && e.target ? e.target.value : null;
      emit('input', value);
    };

    return {
      onInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.radio-input {
  position: relative;
  margin: 0 0 2rem;
  $radio-size: 2rem;

  .radio {
    padding-left: 1rem;
  }

  input[type='radio'] {
    width: $radio-size;
    height: $radio-size;
    vertical-align: middle;
  }
}
</style>
