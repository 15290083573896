<template>
  <div class="field text-input">
    <label v-if="label" :for="id" class="label"
      ><span>{{ label }}</span>
      <span class="is-danger-text" v-if="required"> *</span>
    </label>
    <div class="control has-icons-right">
      <textarea
        v-if="isTextarea"
        :id="id"
        :name="name"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :value="fieldValue"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        :rows="textareaRows"
        class="textarea"
        @input="onInput"
        @blur="$emit('onBlur', $event)"
      />
      <input
        v-else
        :id="id"
        :name="name"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :type="inputType"
        :value="fieldValue"
        :required="required"
        :disabled="disabled"
        :readonly="readonly"
        class="input"
        @input="onInput"
        @blur="$emit('onBlur', $event)"
      />
      <LegoIcon
        v-if="stateIcon && validation"
        :style="{
          color: stateIconColor,
        }"
        class="is-small is-right"
        :icon="stateIcon"
      />
    </div>
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';
import useValidationIcon from '@/composables/useValidationIcon';
import LegoIcon from '@/components/base/LegoIcon';

export default {
  name: 'LegoTextInput',
  emits: ['update:modelValue', 'onInput', 'onBlur'],
  components: { LegoIcon },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    inputType: {
      type: String,
      validator: function (value) {
        return (
          [
            'text',
            'tel',
            'number',
            'date',
            'email',
            'password',
            'textarea',
          ].indexOf(value) !== -1
        );
      },
      default: 'text',
    },
    validation: {
      type: [Object],
      required: false,
      default: null,
    },
    textareaRows: {
      type: Number,
      default: 6,
    },
  },
  setup(props, { emit }) {
    const { modelValue, validation, inputType } = toRefs(props);
    const { stateIcon, stateIconColor } = useValidationIcon(validation);
    const fieldValue = modelValue;
    const id = computed(() => `${props.name}-id`);
    const isTextarea = computed(() => inputType.value === 'textarea');

    const onInput = (e) => {
      emit('update:modelValue', e?.target?.value);
      emit('onInput', e);
    };

    return {
      fieldValue,
      id,
      onInput,
      stateIcon,
      stateIconColor,
      isTextarea,
    };
  },
};
</script>

<style lang="scss" scoped>
.text-input {
  position: relative;
}
</style>
