<template>
  <div class="lego-need-assistance-wrapper">
    <LegoInfoBox>
      <h3 class="has-text-centered subtitle mt-3">Need assistance?</h3>
      <figure class="image container is-128x128 mb-5">
        <img
          class="is-rounded"
          src="@/assets/images/order-contact-person2.jpg"
        />
      </figure>

      <p class="mb-5 contact-person">
        We are the Digibox support team, and we're here to help you. Feel free
        to contact us by sending an e-mail to
        <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
      </p>
    </LegoInfoBox>
  </div>
</template>

<script>
import LegoInfoBox from '@/components/base/LegoInfoBox';

export default {
  name: 'LegoNeedAssistance',
  components: { LegoInfoBox },
  setup() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/helpers';

.lego-need-assistance-wrapper {
  margin: 1rem;

  @media (min-width: $lego-breakpoints-tablet) {
    margin: 2.4rem 2rem;

    .lego-info-box {
      max-width: 20rem;
      margin: 0 auto;
    }
  }
}

.contact-person {
  font-size: $lego-icon-font-size;
}
</style>
