<template>
  <div class="static-page installment-procedure-page">
    <h1 class="title">Installation procedure</h1>
    <LegoCollapsibleItem :expanded="false" class="mb-5">
      <template #header
        >Installation procedure for Digibox DB3<br />
        <span class="subtitle is-5 has-text-grey">This version fits NRP</span>
      </template>
      <template #content>
        <div class="content">
          <p>
            We highly recommend that you watch the video below before installing
            your LEGO Digibox.
          </p>
          <p>
            Before starting the setup and installation procedure please read
            <router-link to="/requirements">the requirements</router-link>.
          </p>
          <p>
            <strong
              >As supplement to the video, here is a short step-by-step
              description:</strong
            >
          </p>
          <ol>
            <li>Unscrew the side of the shipping box</li>
            <li>Carefully take out the box (requires 2 people)</li>
            <li>Plug in your internet cable in the computer inside the box</li>
            <li>
              Check that the HDMI-cable is plugged into the computer and the
              screen/monitor
            </li>
            <li>
              Check that the camera is connected to the computer and is pointing
              in the desired direction
            </li>
            <li>
              Check that your store lighting setup is appropriate, as described
              in
              <router-link to="/requirements">the requirements</router-link>
            </li>
            <li>
              Connect the power and the system will automatically start up
            </li>
            <li>
              Test that the application is working with one of the supported
              LEGO boxes. You can find information on supported sets in our
              <router-link to="/updates">latest update</router-link>
            </li>
            <li>
              After installation please reach out to us at
              <a href="mailto:lego@proreact.dk">lego@proreact.dk</a> to make
              sure that we can support it remotely.
            </li>
          </ol>
          <p>
            The Digibox is preconfigured to start up early morning and close
            down late evening. If something is not working as intended, please
            read the
            <router-link to="/faq">FAQ</router-link>.
          </p>
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/834682264?h=cae6b4fb5b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="DigiBox DB3 - How to Install"
            ></iframe>
          </div>
          <p>
            <i>*Note: Video has no audio</i>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoCollapsibleItem :expanded="false" class="mb-6">
      <template #header
        >Installation procedure for DB2 / Legacy<br />
        <span class="subtitle is-5 has-text-grey"
          >This version fits yellow stores</span
        >
      </template>
      <template #content>
        <div class="content">
          <p>
            We highly recommend that you watch the video below before installing
            your LEGO Digibox.
          </p>
          <p>
            Before starting the setup and installation procedure please read
            <router-link to="/requirements">the requirements</router-link>.
          </p>
          <p>
            <strong
              >As supplement to the video, here is a short step-by-step
              description:</strong
            >
          </p>
          <ol>
            <li>Unscrew the side of the shipping box</li>
            <li>Carefully take out the box (requires 2 people)</li>
            <li>Plug in your internet cable in the computer inside the box</li>
            <li>
              Check that the HDMI-cable is plugged into the computer and the
              screen/monitor
            </li>
            <li>
              Check that the camera is connected to the computer and is pointing
              in the desired direction
            </li>
            <li>
              Check that your store lighting setup is appropriate, as described
              in
              <router-link to="/requirements">the requirements</router-link>
            </li>
            <li>
              Connect the power and the system will automatically start up. It
              can take up to 5 minutes before the application is up and running
            </li>
            <li>
              Test that the application is working with one of the supported
              LEGO boxes. You can find information on supported sets in our
              <router-link to="/updates">latest update</router-link>
            </li>
            <li>
              After installation please reach out to us at
              <a href="mailto:lego@proreact.dk">lego@proreact.dk</a> to make
              sure that we can support it remotely.
            </li>
          </ol>
          <p>
            The Digibox is preconfigured to start up early morning and close
            down late evening. If something is not working as intended, please
            read the
            <router-link to="/faq">FAQ</router-link>.
          </p>
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/834681941?h=ae778c8d23&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              title="DigiBox DB2 - How to install"
            ></iframe>
          </div>
          <p>
            <i>*Note: Video has no audio</i>
          </p>
        </div>
      </template>
    </LegoCollapsibleItem>

    <LegoSwapUnit />
  </div>
</template>

<script>
import { useMeta } from 'vue-meta';

import LegoCollapsibleItem from '@/components/base/LegoCollapsibleItem';
import LegoSwapUnit from '@/components/LegoSwapUnit.vue';

export default {
  name: 'InstallationProcedure',
  components: { LegoSwapUnit, LegoCollapsibleItem },
  setup() {
    useMeta({
      title: 'Installation procedure',
    });
  },
};
</script>
